import React, { useContext, useEffect } from 'react';
import {
  CardContainer, Container,
  ContentContainer,
  ContentContainerColumn, Form, FormGrid, FormSection,
  HeadingContainer
} from '../../../../chakra-lib/layout';
import { Alert, Heading, InputDateForm, InputForm, SelectForm } from '../../../../chakra-lib';
import { Field, Formik } from 'formik';
import { Button, useToast } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { TranslationContext } from '../../../../translation';
import { isEmpty } from 'lodash';
import { getDateReadFormat, getDateToSubmit } from '../../../../chakra-lib/utils/dateFormatting';
import { getMedicalFacilityList, resetMedicalFacilityList } from '../../../../store/medicalFacility.reducer';
import { createPhase, getPhase, resetPhase, updatePhase } from '../../../../store/vaccination.reducer';
import { ROLES } from '../../../../model/roles';

const validationSchema = Yup.object().shape({
  vaccineSeries: Yup.string().required('Required'),
  medicalFacilityId: Yup.string().required('Required'),
  vaccinationDate: Yup.string().required('Required')
});

export const PhaseUpdate = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id, phaseId} = useParams();
  const toast = useToast();
  const isNew = !phaseId;
  const userAuth = useSelector(state => state.authentication.user);
  const item = useSelector(state => state.vaccination.itemPhase);
  const loading = useSelector(state => state.vaccination.loading);
  const loadingUpdate = useSelector(state => state.vaccination.loadingUpdate);
  const errorMessage = useSelector(state => state.vaccination.errorMessage);
  const itemsMedicalFacility = useSelector(state => state.medicalFacility.items);
  const loadingMedicalFacility = useSelector(state => state.medicalFacility.loading);

  const handleBack = () => {
    history.goBack();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (userAuth.role === ROLES.Administrator || userAuth.role === ROLES.UserEditor) {
      dispatch(getMedicalFacilityList());
    }
    if (isNew) {
      dispatch(resetPhase());
    } else {
      dispatch(getPhase(id, phaseId));
    }
    return () => {
      dispatch(resetPhase());
      dispatch(resetMedicalFacilityList());
    };
  }, []);

  const handleForm = async (values, actions) => {
    const dataRequest = {
      id: !isNew ? phaseId : undefined,
      vaccinationId: id,
      vaccineSeries: values.vaccineSeries,
      vaccinationDate: getDateToSubmit(values.vaccinationDate),
      vaccinationDose: values.vaccinationDose,
      doctor: values.doctor,
      medicalFacilityId: values.medicalFacilityId
    };
    if (isNew) {
      await dispatch(createPhase(dataRequest));
      toast({
        title: 'Готово',
        description: 'Фаза успешно добавлениа',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    } else {
      await dispatch(updatePhase(id, phaseId, dataRequest));
      toast({
        title: 'Готово',
        description: 'Фаза успешно обновлена',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    }
  };

  return (
    <Container>
      <CardContainer indentTop={70} onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Добавление фазы' : 'Обновление фазы'}
              </Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="1.875rem">
                {errorMessage?.error}
              </Alert>
            )}
            <Formik
              initialValues={{
                vaccineSeries: item?.vaccineSeries,
                vaccinationDate: item?.vaccinationDate ? getDateReadFormat(item?.vaccinationDate) : '',
                vaccinationDose: item?.vaccinationDose,
                doctor: item?.doctor,
                medicalFacilityId: isNew ? userAuth.medicalFacilityId : item?.medicalFacilityId,
              }}
              onSubmit={handleForm}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={loadingUpdate || loading}
                    >
                      {isNew ? 'Добавить' : getTranslation('test.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <FormGrid columns={2}>
                      <Field
                        autoFocus
                        name="vaccineSeries"
                        label="Серия вакцины с упаковки"
                        component={InputForm}
                      />
                      <Field
                        name="vaccinationDose"
                        label="Доза вакцины"
                        component={InputForm}
                      />
                    </FormGrid>
                    {(userAuth.role === ROLES.Administrator || (userAuth.role === ROLES.UserEditor && isEmpty(userAuth.medicalFacilityId))) && (
                      <Field
                        name="medicalFacilityId"
                        label="Медицинское учреждение"
                        component={SelectForm}
                        placeholder="Выберите из списка"
                        isLoading={loadingMedicalFacility}
                        options={
                          !isEmpty(itemsMedicalFacility) && itemsMedicalFacility.map(item => ({
                            value: item.id,
                            label: item.title?.ru
                          }))
                        }
                      />
                    )}
                    <Field
                      name="doctor"
                      label="ФИО доктора"
                      component={InputForm}
                    />
                    <FormGrid columns={2}>
                      <Field
                        name="vaccinationDate"
                        component={InputDateForm}
                        label="Дата вакцинации"
                        placeholder="01.01.2021"
                      />
                    </FormGrid>
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
