import React, { useEffect, useRef } from 'react'
import { CardContainer, Container } from 'chakra-lib/layout'
import { Box } from '@chakra-ui/react'
import { isEmpty } from 'components/deprecated/utilities/is-empty'
import { VaccinationCertificatePage1 } from 'content/VaccinationCertificate'
import { useHistory, useParams } from 'react-router-dom'
import {
  getPhaseList,
  getVaccination,
  resetPhaseList,
  resetVaccination,
} from 'store/vaccination.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { VaccinationCertificatePage2 } from 'content/VaccinationCertificate'

export const PrintVaccinationCertificate = () => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const vaccinationCertificatePage1 = useRef()
  const vaccinationCertificatePage2 = useRef()
  const item = useSelector((state) => state.vaccination.item)
  const itemsPhase = useSelector((state) => state.vaccination.itemsPhase)
  const handleBack = () => {
    history.goBack()
  }
  const handlePrint1 = useReactToPrint({
    content: () => vaccinationCertificatePage1.current,
  })
  const handlePrint2 = useReactToPrint({
    content: () => vaccinationCertificatePage2.current,
  })

  useEffect(() => {
    dispatch(getVaccination(id))
    dispatch(getPhaseList(id))
    return () => {
      dispatch(resetVaccination())
      dispatch(resetPhaseList())
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.querySelector('html').style.overflow = 'hidden'
  }, [])

  return (
    <Container>
      <CardContainer onBack={handleBack}>
        <Box
          padding="1.875rem 0 80px"
          width="1060px"
          cursor="pointer"
          onClick={handlePrint1}
          transition="0.2s"
          _hover={{
            opacity: 0.9,
          }}
        >
          <Box
            boxShadow="rgba(149, 157, 165, 0.26) 0px 0.5rem 1.75rem"
            backgroundColor="white"
            width="1000px"
            ml="auto"
            mr="auto"
            // borderWidth={1}
          >
            <VaccinationCertificatePage1
              ref={vaccinationCertificatePage1}
              medicalFacilityTitle={
                !isEmpty(itemsPhase) && itemsPhase[itemsPhase.length - 1]?.medicalFacilityTitle?.ru
              }
              id={id}
            />
          </Box>
        </Box>
        <Box
          padding="1.875rem 0 80px"
          width="1060px"
          cursor="pointer"
          onClick={handlePrint2}
          transition="0.2s"
          _hover={{
            opacity: 0.9,
          }}
        >
          <Box
            boxShadow="rgba(149, 157, 165, 0.26) 0px 0.5rem 1.75rem"
            backgroundColor="white"
            width="1000px"
            ml="auto"
            mr="auto"
            // borderWidth={1}
          >
            {!isEmpty(itemsPhase) && (
              <VaccinationCertificatePage2
                item={item}
                ref={vaccinationCertificatePage2}
                itemsPhase={itemsPhase}
              />
            )}
          </Box>
        </Box>
      </CardContainer>
    </Container>
  )
}
