import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Text,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  MenuItem,
  useColorMode,
  HStack,
} from '@chakra-ui/react'
import { ChevronDownIcon, DownloadIcon, RepeatIcon } from '@chakra-ui/icons'
import { TranslationContext } from 'translation'
import { useProfileInfo } from './hooks/useProfileInfo'
import { useFetchSyncLabData } from './_services'
import { useSelector } from 'react-redux'
import { ROLES } from 'model/roles'
import { Private } from 'components/Private'
import { useUploadSyncLabData } from 'components/ProfileWidget/_services/useUploadSyncLabData'

const ProfileWidget = ({ downloadSyncData, uploadSyncData }) => {
  const history = useHistory()
  const fileInputRef = React.useRef(null)
  const { language, setLanguage, getTranslation } = useContext(TranslationContext)
  const { colorMode, toggleColorMode } = useColorMode()
  const { user, roleType } = useProfileInfo()
  const userAuth = useSelector((state) => state.authentication.user)
  const { request: fetchSyncLabData, isLoading: fetchSyncLabDataLoading } = useFetchSyncLabData()
  const { request: sendSyncLabData, isLoading: sendSyncLabDataLoading } = useUploadSyncLabData()

  const handleLogout = () => {
    history.push('/logout')
  }

  const getLaboratoryData = useCallback(() => {
    fetchSyncLabData()
  }, [])

  const onUploadClick = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const onUploadFile = useCallback((event) => {
    const file = event.target.files[0]

    const fileReader = new FileReader()
    fileReader.onload = function (e) {
      const result = JSON.parse(e.target.result)
      sendSyncLabData(result)
    }

    fileReader.readAsText(file)
  }, [])

  return (
    <Box>
      <Box display="flex" role="group" justifyContent="space-between" transition="0.3s">
        <Box flexGrow={1} textAlign="right">
          <Text fontSize="sm" transition="0.3s" fontWeight="600">
            {user.name}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {roleType}
          </Text>
        </Box>
        <Menu>
          <MenuButton
            as={IconButton}
            isLoading={sendSyncLabDataLoading || fetchSyncLabDataLoading}
            aria-label={'Menu'}
            {...{
              marginLeft: '0.875rem',
              icon: <ChevronDownIcon />,
              variant: 'outline',
              size: 'md',
              isRound: true,
            }}
          />
          <MenuList zIndex={30} fontSize="1rem">
            <MenuOptionGroup
              onChange={setLanguage}
              value={language}
              title={getTranslation('userPanel.language')}
              type="radio"
            >
              <MenuItemOption value="ru">Русский</MenuItemOption>
              <MenuItemOption value="en">English</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              title={getTranslation('userPanel.theme')}
              type="radio"
              value={colorMode}
              onChange={toggleColorMode}
            >
              <MenuItemOption value="light">Светлая</MenuItemOption>
              <MenuItemOption value="dark">Темная</MenuItemOption>
            </MenuOptionGroup>
            {downloadSyncData && (
              <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                <MenuDivider />
                <MenuItem onClick={getLaboratoryData}>
                  <HStack>
                    <DownloadIcon />
                    <Text>{getTranslation('userPanel.downloadSyncData')}</Text>
                  </HStack>
                </MenuItem>
              </Private>
            )}
            {uploadSyncData && (
              <>
                <MenuDivider />
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={onUploadFile}
                />
                <MenuItem onClick={onUploadClick}>
                  <HStack>
                    <RepeatIcon />
                    <Text>{getTranslation('userPanel.uploadSyncData')}</Text>
                  </HStack>
                </MenuItem>
              </>
            )}
            <MenuDivider />
            <MenuItem onClick={handleLogout}>{getTranslation('userPanel.logout')}</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  )
}

export default ProfileWidget