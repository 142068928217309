import { api } from 'api'
import { useQuery } from 'components/useQuery'

export const useNominationReverse = (initialParams) => {
  const { response, request, ...state } = useQuery(async (params) => {
    return await api.nomination.reverse({ ...initialParams, ...params })
  })

  return {
    ...state,
    response,
    request,
  }
}

