import React, { useEffect } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { TestStatement } from '../../content/TestStatement';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTest, resetTest } from '../../store/test.reducer';
import { isEmpty } from '../../components/deprecated/utilities/is-empty';
import { CardContainer, Container } from '../../chakra-lib/layout';

export const PrintTest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const settingsLaboratory = useSelector(state => state.settings.settingsLaboratory);
  const country = useSelector(state => state.settings.country);
  const item = useSelector(state => state.test.item);
  const loading = useSelector(state => state.test.loading);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getTest(id));
    return () => {
      dispatch(resetTest());
    }
  }, []);

  const handleBack = () => {
    history.goBack();
  }

  return (
    <Container>
      <CardContainer onBack={handleBack}>
        <Flex
          flexDirection="column"
          alignItems="center"
          padding="1.875rem 0 80px"
        >
          <Flex
            width="900px"
            height="1100px"
            boxShadow="rgba(149, 157, 165, 0.26) 0px 0.5rem 1.75rem"
            backgroundColor="white"
            alignItems="center"
            justifyContent="center"
            // borderWidth={1}
          >
            {loading ? (
              <Spinner
                thickness="0.25rem"
                speed="0.65s"
                color="blue.500"
                size="lg"
              />
            ) : (
              !isEmpty(item) ? (
                <TestStatement
                  item={item}
                  laboratoryName={settingsLaboratory.name}
                  country={country}
                />
              ) : ('Нет данных')
            )}
          </Flex>
        </Flex>
      </CardContainer>
    </Container>
  );
};
