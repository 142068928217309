import React, { useContext } from 'react'
import { Box, Flex, IconButton, Text, useBoolean, useColorMode, VStack } from '@chakra-ui/react'
import { TranslationContext } from 'translation'
import { Drawer } from 'chakra-lib/Drawer'
import { HamburgerIcon } from '@chakra-ui/icons'
import MenuGroup from 'components/ProfileWidget/ProfileWidgetMobile/MenuGroup'
import { useProfileInfo } from 'components/ProfileWidget/hooks/useProfileInfo'
import { useHistory } from 'react-router-dom'

const ProfileWidgetMobile = ({ renderLogo, renderNavigation }) => {
  const [isOpen, setIsOpen] = useBoolean()
  const btnRef = React.useRef()

  const { language, setLanguage, getTranslation } = useContext(TranslationContext)
  const { colorMode, setColorMode } = useColorMode()
  const { user, roleType } = useProfileInfo()

  const history = useHistory()

  const handleLogout = () => {
    history.push('/logout')
  }

  return (
    <Box>
      <Flex
        px="1rem"
        h="3.5rem"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        {renderLogo}
        <IconButton
          aria-label="Главное меню"
          icon={<HamburgerIcon />}
          variant="unstyled"
          fontSize="1.5rem"
          color="teal"
          onClick={setIsOpen.on}
        />
      </Flex>
      <Drawer
        title={
          <Box>
            <Text fontSize="sm" transition="0.3s" fontWeight="600">
              {user.name}
            </Text>
            <Text fontSize="xs" color="gray.500">
              {roleType}
            </Text>
          </Box>
        }
        isOpen={isOpen}
        onClose={setIsOpen.off}
        finalFocusRef={btnRef}
      >
        <VStack spacing={2} alignItems="flex-start">
          {renderNavigation}
          <MenuGroup
            title={getTranslation('userPanel.language')}
            onChange={setLanguage}
            defaultValue={language}
            options={[
              {
                label: 'Русский',
                value: 'ru',
              },
              {
                label: 'English',
                value: 'en',
              },
            ]}
          />
          <MenuGroup
            title={getTranslation('userPanel.theme')}
            onChange={setColorMode}
            defaultValue={colorMode}
            options={[
              {
                label: 'Светлая',
                value: 'light',
              },
              {
                label: 'Темная',
                value: 'dark',
              },
            ]}
          />
          <Text alignSelf="flex-end" onClick={handleLogout}>
            {getTranslation('userPanel.logout')}
          </Text>
        </VStack>
      </Drawer>
    </Box>
  )
}

export default ProfileWidgetMobile