
export const USER_FIELDS = {
  login: 'login',
  name: 'name',
  role: 'role',
  state: 'state',
}

export const USER_FIELDS_TRANSLATION = {
  [USER_FIELDS.login]: 'user.snippets.heading.login',
  [USER_FIELDS.name]: 'user.snippets.heading.name',
  [USER_FIELDS.role]: 'user.snippets.heading.role',
  [USER_FIELDS.state]: 'user.snippets.heading.state',
}