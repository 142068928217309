import React from 'react'
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export const Modal = ({ title, isOpen, onClose, footer, children }) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton mt={1} fontSize="1rem" />
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter paddingBottom="1.5rem">{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  )
}

