import React, { useContext, useEffect } from 'react';
import {
  Container, FilterHeader, FilterStack,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../chakra-lib/layout';
import { Snippets, SnippetsItem, SnippetsList, SnippetsHeading, FormLabel } from '../../../../chakra-lib';
import { TranslationContext } from '../../../../translation';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, VStack } from '@chakra-ui/react';
import {
  DATE_FORMAT,
} from '../../../../components/deprecated/utilities/date-formatting';
import { HeadingItem, ItemBadge, ItemLink, ItemText } from '../../../../chakra-lib/Snippets';
import moment from 'moment';
import { getCountryListNavigation, getNextPage, resetCountryList } from '../../../../store/country.reducer';
import { ItemAvatar } from '../../../../components/deprecated/snippets';
import { SettingsTestValidHours } from './elements/SettingsTestValidHours';
import { SettingsInsurance } from './elements/SettingsInsurance';
import { SettingsTopBanner } from './elements/SettingsTopBanner/SettingsTopBanner';
import { SettingsAttentionMessage } from './elements/SettingsAttentionMessage/SettingsAttentionMessage';

const TYPE_COLOR_RESULT = {
  'true': 'red',
  'false': 'green'
};

export const CountryList = () => {
  const { getTranslation } = useContext(TranslationContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const activePage = useSelector(state => state.country.activePage);
  const items = useSelector(state => state.country.items);
  const totalPages = useSelector(state => state.country.totalPages);

  const TYPE_DISABLED = {
    'true': getTranslation('disabled.true'),
    'false': getTranslation('disabled.false')
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getCountryListNavigation());
    return () => {
      dispatch(resetCountryList())
    };
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="2.625rem minmax(200px, 1fr) 180px 180px"
            itemWidth="1100px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>{getTranslation('laboratory.snippets.heading.name')}</HeadingItem>
              <HeadingItem>{getTranslation('laboratory.snippets.heading.createdDate')}</HeadingItem>
              <HeadingItem>{getTranslation('laboratory.snippets.heading.state')}</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={items}
              onNext={() => dispatch(getNextPage())}
            >
              {(item, index) => (
                <SnippetsItem to={`/integration/country/edit/${item.id}`}>
                  <ItemAvatar src={item.flag} />
                  <ItemLink>{item.name && item.name.ru}</ItemLink>
                  <ItemText>{item.createdAt ? moment(item.createdAt).format(DATE_FORMAT) : null}</ItemText>
                  <ItemText>
                    <ItemBadge
                      colorScheme={TYPE_COLOR_RESULT[item.disabled]}
                    >
                      {TYPE_DISABLED[item.disabled]}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/integration/country/new`)}
              colorScheme="blue"
            >
              {getTranslation('test.action.buttonCreate')}
            </Button>
          </FilterHeader>
          <FilterStack>
            <Box>
              <FormLabel>Настройки</FormLabel>
              <VStack spacing="0.625rem">
                <SettingsTestValidHours/>
                <SettingsInsurance/>
                <SettingsTopBanner/>
                <SettingsAttentionMessage/>
              </VStack>
            </Box>
          </FilterStack>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
