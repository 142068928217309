import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import promise from 'redux-promise-middleware';
import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from 'store';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
//import { logger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { bindActionCreators } from 'redux';
import { resetAuth } from 'store/authentication.reducer';
import setupAxiosInterceptors from './axios';
import { ScrollToTop } from 'components/deprecated/scroll-to-top';
import { Translation } from 'translation';
import 'polyfill-object.fromentries';
import { Theme } from 'chakra-lib/Theme/Theme';

export const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk, promise, loadingBarMiddleware())
    //applyMiddleware(thunk, logger, promise)
));

const actions = bindActionCreators({ resetAuth }, store.dispatch);

setupAxiosInterceptors(() => actions.resetAuth());

ReactDOM.render(
    <React.Fragment>
        <Translation>
            <Theme>
                <Router>
                    <ScrollToTop/>
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </Router>
            </Theme>
        </Translation>
    </React.Fragment>,
    document.getElementById('root')
);

serviceWorker.unregister();
