import React, { useContext, useState } from 'react'
import { Button, ModalBody, useDisclosure, Box, Collapse, Flex, Text } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { FormGrid, FormSection } from 'components/deprecated/layout'
import { ModalForm } from 'components/deprecated/modal'
import * as Yup from 'yup'
import { TranslationContext } from 'translation'
import { UIInputDateForm } from 'components/deprecated/input-date'
import { getDateTimeToSubmit } from 'components/deprecated/utilities/date-formatting'
import { getReportTotal, resetReportTotal } from 'store/report.reducer'
import { isEmpty } from 'components/deprecated/utilities/is-empty'
import { SelectForm } from 'chakra-lib'

const formSchema = Yup.object().shape({
  dateFrom: Yup.string().required('Required'),
  dateTo: Yup.string().required('Required'),
})

export const ReportTotal = () => {
  const { getTranslation } = useContext(TranslationContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const itemsLaboratory = useSelector((state) => state.laboratory.items)
  const loading = useSelector((state) => state.report.loading)
  const reportTotal = useSelector((state) => state.report.reportTotal)
  const [testState, setTestState] = useState('all')
  const handleForm = async (values) => {
    const dataRequest = {
      laboratoryId: values.laboratoryId === '' ? undefined : values.laboratoryId,
      dateFrom: getDateTimeToSubmit(values.dateFrom),
      dateTo: getDateTimeToSubmit(values.dateTo),
    }
    await dispatch(getReportTotal(dataRequest))
    setTestState(values.testState)
  }

  const handleCloseModal = () => {
    onClose()
    dispatch(resetReportTotal())
  }

  return (
    <>
      <Button onClick={onOpen} width="100%">
        {getTranslation('reports.action.reportTotal')}
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={handleCloseModal}
        heading={getTranslation('reports.heading.total')}
      >
        <Formik
          initialValues={{
            laboratoryId: '',
            dateFrom: '',
            dateTo: '',
            testState: 'all',
          }}
          onSubmit={handleForm}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormSection mb={0}>
                  <FormGrid columns={2}>
                    <Field
                      label={getTranslation('global.dateFrom')}
                      name="dateFrom"
                      component={UIInputDateForm}
                    />
                    <Field
                      label={getTranslation('global.dateTo')}
                      name="dateTo"
                      component={UIInputDateForm}
                    />
                  </FormGrid>
                  <Field
                    name="laboratoryId"
                    component={SelectForm}
                    label={getTranslation('reports.field.laboratoryId.label')}
                    options={[
                      {
                        value: '',
                        label: getTranslation('reports.field.laboratoryId.optionAll'),
                      },
                      ...(itemsLaboratory &&
                        itemsLaboratory.map((item) => ({
                          value: item.id,
                          label: item.name ? item.name : '<Нет имени>',
                        }))),
                    ]}
                    //onChange={handleSelect}
                  />
                  <Field
                    name="testState"
                    component={SelectForm}
                    label={getTranslation('reports.field.testState.label')}
                    options={[
                      { value: 'all', label: getTranslation('global.all') },
                      { value: 'waiting', label: getTranslation('reports.result.waiting') },
                      { value: 'negative', label: getTranslation('reports.result.false') },
                      { value: 'positive', label: getTranslation('reports.result.true') },
                    ]}
                  />
                </FormSection>
                <Box padding="1.5rem 0 1.5rem">
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isDisabled={!props.dirty}
                    isLoading={loading}
                  >
                    {getTranslation('global.update')}
                  </Button>
                </Box>
                <Collapse mb={1} mt="1.25rem" in={!isEmpty(reportTotal)}>
                  <Box borderWidth={1} padding="1rem" borderRadius={3} backgroundColor="gray.50">
                    <Box mb={2}>
                      {props.values.laboratoryId ? (
                        <Text fontWeight="semibold">{`${
                          itemsLaboratory.find((item) => item.id === props.values.laboratoryId).name
                        }`}</Text>
                      ) : (
                        <Text fontWeight="semibold">Все лаборатории</Text>
                      )}
                      <Text fontSize="0.9rem">{`Дата с ${props.values.dateFrom} по ${props.values.dateTo}`}</Text>
                    </Box>
                    {testState === 'all' && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{getTranslation('reports.result.allTest')}</Text>
                        <Text fontWeight="semibold">
                          {String(
                            reportTotal?.negative + reportTotal?.positive + reportTotal?.wating
                          )}
                        </Text>
                      </Flex>
                    )}
                    {(testState === 'all' || testState === 'waiting') && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{getTranslation('reports.result.waiting')}</Text>
                        <Text fontWeight="semibold">{reportTotal?.wating}</Text>
                      </Flex>
                    )}
                    {(testState === 'all' || testState === 'negative') && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{getTranslation('reports.result.false')}</Text>
                        <Text fontWeight="semibold">{reportTotal?.negative}</Text>
                      </Flex>
                    )}
                    {(testState === 'all' || testState === 'positive') && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{getTranslation('reports.result.true')}</Text>
                        <Text fontWeight="semibold">{reportTotal?.positive}</Text>
                      </Flex>
                    )}
                  </Box>
                </Collapse>
              </ModalBody>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  )
}
