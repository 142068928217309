import React, { useContext } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { TranslationContext } from '../../../../translation';

export const LoadingSettings = () => {
    const { getTranslation } = useContext(TranslationContext);
    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
            padding="60px"
        >
            <Text>
                {getTranslation('loading')}
            </Text>
            <Spinner color="blue.500" size="md" marginTop="1.25rem" />
        </Box>
    );
};
