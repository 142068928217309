import React, { useContext, useEffect } from 'react';
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../../chakra-lib/layout';
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from '../../../../../chakra-lib';
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from '../../../../../chakra-lib/Snippets';
import { IconCollection } from '../../../../../components/icons';
import { TranslationContext } from '../../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFilterParams } from '../../../../../chakra-lib/utils';
import { FaqListFilter } from './elements/FaqListFilter';
import { FILTER_FAQ_PARAMS } from '../../../../../config/faq.config';
import { getNextPage, resetFilter, updateFilter } from '../../../../../store/faq.reducer';
import { Box, Text } from '@chakra-ui/react';
import { truncate } from '../../../../../components/deprecated/utilities/truncate';

const TYPE_COLOR_DISABLED = {
  'true': 'red',
  'false': 'green'
}

export const FaqList = () => {
  const { getTranslation } = useContext(TranslationContext);
  const dispatch = useDispatch();
  const {params} = useParams();
  const items = useSelector(state => state.faq.items);
  const activePage = useSelector(state => state.faq.activePage);
  const totalPages = useSelector(state => state.faq.totalPages);

  const TYPE_DISABLED = {
    'true': getTranslation('disabled.true'),
    'false': getTranslation('disabled.false')
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_FAQ_PARAMS, params);
      dispatch(updateFilter(objFilterParams));
    } else {
      dispatch(updateFilter());
    }
  }, [params]);
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={120}>
        <SnippetsContainerMain>
          <Snippets
            columns="2.625rem minmax(200px, 1fr) 160px 160px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>Заголовок</HeadingItem>
              <HeadingItem>Сортировка</HeadingItem>
              <HeadingItem>Статус</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              onNext={() => dispatch(getNextPage())}
              data={items}
            >
              {(item, index) => (
                <SnippetsItem to={`/integration/dictionaries/faq/edit/${item.id}`}>
                  <ItemIcon icon={IconCollection} />
                  <ItemLink>
                    <Box> {truncate(item.chapter?.ru, 50)}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {truncate(item.chapter?.en, 50)}
                    </Text>
                  </ItemLink>
                  <ItemText>{String(item.orderNr)}</ItemText>
                  <ItemText>
                    <ItemBadge
                      colorScheme={TYPE_COLOR_DISABLED[item.hidden]}
                    >
                      {TYPE_DISABLED[item.hidden]}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FaqListFilter params={params}/>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
