import React, { useContext, useEffect } from 'react';
import { Link as LinkRouter, useHistory, useParams } from 'react-router-dom';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, GridField, GridFieldItem,
  HeadingContainer
} from '../../../../chakra-lib/layout';
import { Heading } from '../../../../chakra-lib';
import { TranslationContext } from '../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import { getTestHistory, resetTestHistory } from '../../../../store/test.reducer';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex, Link,
} from '@chakra-ui/react';
import { getDateReadFormat, getTimeReadFormat } from '../../../../chakra-lib/utils/dateFormatting';
import { find, isEmpty } from 'lodash';
import { getUserList, resetUserList } from '../../../../store/user.reducer';

export const TestCardHistory = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const loading = useSelector(state => state.test.loading);
  const item = useSelector(state => state.test.itemHistory);
  const itemsUser = useSelector(state => state.user.items);
  const handleBack = () => {
    history.goBack();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getTestHistory(id));
    dispatch(getUserList())
    return () => {
      dispatch(resetTestHistory());
      dispatch(resetUserList())
    }
  },[]);

  const getResult = (resultTest) => {
    if (resultTest === null) {
      return getTranslation('global.pending')
    }
    return resultTest ? getTranslation('resultTest.true') : getTranslation('resultTest.false')
  }

  const getUser = (itemHistory) => {
    return find(itemsUser, { id: itemHistory?.changeUserId})
  }

  return (
    <Container>
      <CardContainer indentTop={70} onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading fontSize="1.4rem">{getTranslation('test.heading.history')}</Heading>
            </HeadingContainer>
            {!isEmpty(item) ? (
              <Accordion allowMultiple>
                {item.map(itemHistory => {
                  const user = getUser(itemHistory);
                  return (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" pt="0.5rem" pb="0.5rem" fontWeight="semibold">
                            {getDateReadFormat(itemHistory?.createdAt)}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} pl={0} pr={0}>
                        <GridField>
                          <Box
                            pl="1rem"
                            pr="1rem"
                            fontSize="0.9rem"
                            pb="1.25rem"
                          >
                            Обновлено пользователем:
                            <Link
                              as={LinkRouter}
                              to={`/laboratory/user/edit/${user?.id}`}
                              color="blue.300"
                              ml={2}
                            >{user?.name}</Link>
                          </Box>
                          <GridFieldItem
                            label={getTranslation('test.field.fio.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.fio}
                          </GridFieldItem>
                          <GridFieldItem
                            label={getTranslation('test.field.passport.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.passport}
                          </GridFieldItem>
                          <GridFieldItem
                            label={getTranslation('test.field.telephone.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.telephone}
                          </GridFieldItem>
                          <GridFieldItem
                            label={getTranslation('test.field.email.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.email}
                          </GridFieldItem>
                          <GridFieldItem
                            label="Результат тестирования"
                            isLoading={loading}
                            isReady
                          >
                            {getResult(itemHistory?.testValue?.result)}
                          </GridFieldItem>
                          <GridFieldItem
                            label={getTranslation('test.field.testingDate.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.testingDate && `${getDateReadFormat(itemHistory?.testValue?.testingDate)}, ${getTimeReadFormat(itemHistory?.testValue?.testingDate)}`}
                          </GridFieldItem>
                          <GridFieldItem
                            label={getTranslation('test.field.resultDate.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.resultDate && (
                              `${getDateReadFormat(itemHistory?.testValue?.resultDate)}, ${getTimeReadFormat(itemHistory?.testValue?.resultDate)} `
                            )}
                          </GridFieldItem>
                          <GridFieldItem
                            label={getTranslation('test.field.resultSetDate.label')}
                            isLoading={loading}
                            isReady
                          >
                            {itemHistory?.testValue?.resultSetDate && (
                              `${getDateReadFormat(itemHistory?.testValue?.resultSetDate)}, ${getTimeReadFormat(itemHistory?.testValue?.resultSetDate)}`
                            )}
                          </GridFieldItem>
                        </GridField>
                      </AccordionPanel>
                    </AccordionItem>
                  )
                })}
              </Accordion>
            ) : (
              <Flex
                width="100%"
                height="200px"
                alignItems="center"
                justifyContent="center"
                color="gray.400"
                borderTopWidth={1}
                borderBottomWidth={1}
              >
                {'Нет данных'}
              </Flex>
            )}
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
