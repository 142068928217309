import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

export const BackButton = ({ onClick, ...props }) => {
  const themeBgHover = useColorModeValue('gray.100', 'gray.700');
  const themeBgActive = useColorModeValue('gray.200', 'gray.700');
  return (
    <Flex
      flex={1}
      {...props}
    >
      <Flex
        onClick={onClick}
        cursor="pointer"
        flex={1}
        transition="0.3s"
        _hover={{
          backgroundColor: themeBgHover
        }}
        _active={{
          backgroundColor: themeBgActive
        }}
      >
        <Flex height="104px" width="100%" alignItems="center" justifyContent="center">
          <ArrowBackIcon boxSize="1.25rem"/>
        </Flex>
      </Flex>
    </Flex>
  );
};
