import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { TranslationContext } from 'translation'
import { ROLES } from 'model/roles'

export const useProfileInfo = () => {
  const { getTranslation } = useContext(TranslationContext)
  const user = useSelector((state) => state.authentication.user)

  const ROLE_TYPE = {
    [ROLES.Administrator]: getTranslation('role.administrator'),
    [ROLES.User]: getTranslation('role.user'),
    [ROLES.UserPrivileged]: getTranslation('role.userPrivileged'),
    [ROLES.Insurer]: getTranslation('role.insurer'),
    [ROLES.UserCreator]: getTranslation('role.userCreator'),
    [ROLES.UserResultSetter]: getTranslation('role.userResultSetter'),
    [ROLES.UserLab]: getTranslation('role.userLab'),
    [ROLES.UserEditor]: getTranslation('role.userEditor'),
    [ROLES.Api]: getTranslation('role.api'),
  }

  return {
    user,
    roleType: ROLE_TYPE[user.role],
    ROLE_TYPE,
  }
}
