import { api } from 'api'
import { useQuery } from 'components/useQuery'
import toast from 'utils/toast'

export const useLaboratorySettings = () => {
  return useQuery(
    async (params) => {
      return await api.settings.sendTrustedLaboratories(params)
    },
    {
      onError: (error) => {
        toast.showError({
          title: 'Произошла ошибка при изменении статуса',
          description: error.error,
        })
      },
    }
  )
}

