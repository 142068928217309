import React from 'react';
import { VStack } from '@chakra-ui/react';

export const FilterStack = ({ children, ...props }) => {
  return (
    <VStack
      spacing={5}
      pt="1.625rem"
      pr="1.625rem"
      pl="1.625rem"
      width="100%"
      pb="1.875rem"
      alignItems="inherit"
      {...props}
    >
      {children}
    </VStack>
  );
};
