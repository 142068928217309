import React from 'react';
import { Flex, Avatar } from '@chakra-ui/react';

export const ItemAvatar = ({src, name = 'Russia', width = '2rem', height = '2rem', props}) => {
  return (
    <Flex align="center" justify="center" {...props}>
      <Avatar
        width={width}
        height={height}
        name={name}
        src={src}
      />
    </Flex>
  );
};
