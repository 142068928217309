import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNextPage, resetFilter, resetOfficeList, updateFilter } from 'store/office.reducer'
import { getFilterParams } from 'chakra-lib/utils'
import { FILTER_OFFICE_PARAMS } from 'config/office.config'
import { OfficeListFilter } from './elemenst/OfficeListFilter'
import { exportExcelFile } from 'utils/exportExcelFile'
import { makeExcelColumns } from './OfficeList.excel'
import { STORAGE_TABLE } from 'model/localStorage'
import { makeColumns } from './OfficeList.config'
import { OFFICE_FIELDS } from '../Office.constants'
import Register from 'components/layout/Register'

const OfficeList = () => {
  const tableRef = useRef()
  const history = useHistory()
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const { params } = useParams()
  const activePage = useSelector((state) => state.office.activePage)
  const items = useSelector((state) => state.office.items)
  const totalPages = useSelector((state) => state.office.totalPages)
  const loading = useSelector((state) => state.office.loading)
  const userAuth = useSelector((state) => state.authentication.user)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_OFFICE_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])
  useEffect(() => {
    return () => {
      dispatch(resetFilter())
      dispatch(resetOfficeList())
    }
  }, [])

  const exportExcelHandle = useCallback(() => {
    const hiddenColumns = tableRef.current.state.hiddenColumns

    exportExcelFile(items, {
      sheetName: 'Office',
      columns: makeExcelColumns({ getTranslation, hiddenColumns }),
    })
  }, [items])

  const columns = useMemo(() => makeColumns({ getTranslation }), [])

  const onRowClick = useCallback(({ original }) => {
    history.push(`/laboratory/office/edit/${original.id}`)
  }, [])

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        OFFICE_FIELDS.shortTitleRu,
        OFFICE_FIELDS.siteUrl,
        OFFICE_FIELDS.addressRu,
        OFFICE_FIELDS.addressLegal,
        OFFICE_FIELDS.inn,
        OFFICE_FIELDS.oid,
        OFFICE_FIELDS.ogrn,
        OFFICE_FIELDS.company,
        OFFICE_FIELDS.ownership,
      ],
    }),
    []
  )

  return (
    <Register
      title={getTranslation('menu.office')}
      rightPanel={<OfficeListFilter params={params} onExportExcel={exportExcelHandle} />}
    >
      <Table
        ref={tableRef}
        data={items}
        columns={columns}
        isLoading={loading}
        hasNext={activePage < totalPages}
        onNextPage={() => dispatch(getNextPage())}
        onRowClick={onRowClick}
        initialState={initialState}
        storageId={`${STORAGE_TABLE.laboratoryOffice}.${userAuth.id}`}
      />
    </Register>
  )
}

export default OfficeList