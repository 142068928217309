import axios from 'axios'
import { API_SERVER_URL } from 'store/config'

const ENTITY = 'settings'

export const settings = Object.freeze({
  async setPushEnabled(value) {
    return await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'status_push_enabled',
      value,
    })
  },
  async fetchPushEnabled() {
    return await axios.get(`${API_SERVER_URL}/${ENTITY}/status_push_enabled`)
  },
  async setFormSamplesEnabled(value) {
    return await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'form_samples_enabled',
      value,
    })
  },
  async fetchFormSamplesEnabled() {
    return await axios.get(`${API_SERVER_URL}/${ENTITY}/form_samples_enabled`)
  },
  async setFormSamplesBanner(value) {
    return await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'form_samples_banner',
      value,
    })
  },
  async fetchFormSamplesBanner() {
    return await axios.get(`${API_SERVER_URL}/${ENTITY}/form_samples_banner`)
  },
  async fetchTrustedLaboratoriesStatus() {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/trusted_laboratories_enabled`)
    return response.data
  },
  async sendTrustedLaboratories(value) {
    const response = await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'trusted_laboratories_enabled',
      value,
    })

    return response.data
  },
})
