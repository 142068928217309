import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TestList } from 'pages/laboratory/Test/TestList/TestList';
import { TestCard } from './TestCard/TestCard';
import { TestNew } from './TestNew/TestNew';
import { TestCardHistory } from './TestCard/TestCardHistory';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/show/:id`} exact>
        <TestCard/>
      </Route>
      <Route path={`${match.url}/show/:id/history`} exact>
        <TestCardHistory/>
      </Route>
      <Route path={`${match.url}/new`} exact>
        <TestNew/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <TestList/>
      </Route>
    </Switch>
  );
};

export default Routes;
