import React from 'react';
import { HStack } from '@chakra-ui/react';

export const NavigationMenu = ({ children, ...props }) => {
  return (
    <HStack
      spacing={2}
      pl="1.25rem"
      pr="1.25rem"
      justifySelf="center"
      {...props}
    >
      {children}
    </HStack>
  );
};
