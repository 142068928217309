import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'

import { getAccess, Private } from 'components/Private'
import { ROLES } from 'model/roles'
import { TranslationContext } from 'translation'
import { Button, Collapse, Grid, Link, Text, useToast } from '@chakra-ui/react'
import { useHistory, useParams, Link as LinkRouter } from 'react-router-dom'
import { useTestResult, WidgetTestResult } from 'components/test-result'
import { TEST_RESULT } from 'model/test-result'
import { API_SERVER_URL } from 'store/config'
import { ContentDate } from 'components/content-date'
import { isEmpty } from 'lodash'
import { SendResultForm } from 'pages/laboratory/Test/TestCard/elements/SendResultForm'

export const ActionPanel = ({
  data,
  loading,
  onSendEmail,
  onSendConsentEmail,
  onClientInfoRemove,
  onSendResult,
  onInvalidResult,
  onPrint,
}) => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const toast = useToast()
  const { id } = useParams()
  const user = useSelector((state) => state.authentication.user)

  const { getResult } = useTestResult()

  const { resultTestType } = getResult(data?.result, data?.testingDate)
  const hasPersonalData = Boolean(data?.fio && data?.passport && data?.telephone)
  const isShowActionResult = !isEmpty(data) && data?.result !== null
  const invalidTest = data?.invalid

  const noPersonalDataSubmit = useCallback(() => {
    if (isEmpty(data?.fio)) {
      toast({
        title: 'Введите ФИО',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
      return
    }
    if (isEmpty(data?.passport)) {
      toast({
        title: 'Введите номер паспорта',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
      return
    }
    if (isEmpty(data?.telephone)) {
      toast({
        title: 'Введите телефон',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [data])

  const isPendingSubmitGuard =
    resultTestType !== TEST_RESULT.pending &&
    !getAccess(user.role, [ROLES.Administrator, ROLES.UserPrivileged])

  return (
    <>
      <Text textStyle="h6" marginBottom="0.6rem">
        {getTranslation('test.aside.resultTest')}
      </Text>
      {data && <WidgetTestResult item={data} isLoading={loading} />}
      <Collapse in={!isEmpty(data) && !invalidTest}>
        <Grid gridRowGap="0.9rem" mt="2rem" mb="1.25rem">
          {!getAccess(user.role, [ROLES.Administrator]) && !hasPersonalData ? (
            <Button
              width="100%"
              colorScheme="blue"
              isDisabled={
                resultTestType === TEST_RESULT.delay || getAccess(user.role, [ROLES.UserCreator])
              }
              onClick={noPersonalDataSubmit}
            >
              {getTranslation('test.action.buttonSetResult')}
            </Button>
          ) : (
            <SendResultForm
              isDisabled={
                isPendingSubmitGuard ||
                resultTestType === TEST_RESULT.delay ||
                getAccess(user.role, [ROLES.UserCreator])
              }
              item={data}
              onSendResult={onSendResult}
              onInvalidResult={onInvalidResult}
            />
          )}
          {isShowActionResult && (
            <>
              <Button variant="outline" width="100%" onClick={onSendEmail} isLoading={loading}>
                {getTranslation('test.action.buttonSendEmail')}
              </Button>
              <Button
                variant="outline"
                width="100%"
                onClick={() => window.open(`${API_SERVER_URL}/test/${id}/pdf`, '_blank')}
              >
                {getTranslation('test.action.buttonViewResult')}
              </Button>
            </>
          )}
        </Grid>
        <Text fontSize="0.9rem" color="gray.400">
          {getTranslation('test.aside.statement')}
        </Text>
        <Grid gridRowGap="0.9rem" mt="0.6rem">
          <Button variant="outline" width="100%" onClick={onPrint}>
            {getTranslation('test.action.buttonPrintStatement')}
          </Button>
          <Button variant="outline" width="100%" onClick={() => history.push(`/print/${id}`)}>
            {getTranslation('test.action.buttonViewStatement')}
          </Button>
          <Button
            isDisabled={isEmpty(data?.email)}
            variant="outline"
            width="100%"
            onClick={onSendConsentEmail}
            isLoading={loading}
          >
            {getTranslation('test.action.buttonSendConsentEmail')}
          </Button>
        </Grid>
      </Collapse>
      <Grid gridRowGap="0.9rem" mt="0.6rem">
        <Text fontSize="0.9rem" color="gray.400">
          {getTranslation('test.aside.actions')}
        </Text>
        {user.role === ROLES.Administrator && (
          <Button colorScheme="orange" width="100%" onClick={onClientInfoRemove}>
            {getTranslation('test.action.removeCustomerInfo')}
          </Button>
        )}
      </Grid>
      {data && <ContentDate item={data} loading={loading} />}
      <Private role={user.role} hasAnyRole={[ROLES.Administrator]}>
        <Link
          as={LinkRouter}
          to={`/laboratory/test/show/${data?.id}/history`}
          color="blue.300"
          fontSize="0.7rem"
        >
          История изменения
        </Link>
      </Private>
    </>
  )
}
