import React, { useContext, useEffect } from 'react';
import {
  Container, FilterHeader,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../chakra-lib/layout';
import {
  HeadingItem,
  Snippets,
  SnippetsHeading
} from '../../../../chakra-lib/Snippets';
import { TranslationContext } from '../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { InformationUpdate } from './InformationUpdate';
import { getSettingsInformation, reset } from '../../../../store/settings-information.reducer';

export const InformationList = () => {
  const { getTranslation } = useContext(TranslationContext);
  const dispatch = useDispatch();
  const items = useSelector(state => state.settingsInformation.items);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getSettingsInformation());
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={120}>
        <SnippetsContainerMain>
          <Snippets
            columns="2.625rem minmax(200px, 1fr) 180px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>{getTranslation('settingInformation.snippets.heading.name')}</HeadingItem>
              <HeadingItem>{getTranslation('settingInformation.snippets.heading.actions')}</HeadingItem>
            </SnippetsHeading>
            {!isEmpty(items) && items.map((item, index) => (
              <Box
                key={item.id}
                tabIndex="none"
                _focus={{
                  outline: 'none'
                }}
              >
                <InformationUpdate item={item} index={index} length={items.length}/>
              </Box>
            ))}
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <InformationUpdate/>
          </FilterHeader>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
