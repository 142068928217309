import React from 'react'

import { ItemBadge, ItemIcon, ItemLink } from 'chakra-lib/Snippets'
import { IconPerson } from 'components/icons'
import { USER_FIELDS, USER_FIELDS_TRANSLATION } from '../User.constants'
import { isMobile } from 'react-device-detect'

const TYPE_COLOR_DISABLED = {
  true: 'red',
  false: 'green',
}

export const makeColumns = ({ getTranslation, ROLE_TYPE }) => {
  const TYPE_DISABLED = {
    true: getTranslation('disabled.true'),
    false: getTranslation('disabled.false'),
  }

  return [
    {
      id: 'icon',
      accessor: 'id',
      gridWidth: '3.125rem',
      Cell: () => <ItemIcon icon={IconPerson} />,
    },
    {
      id: USER_FIELDS.login,
      accessor: 'username',
      gridWidth: 'minmax(10rem, 1fr)',
      Cell: ({ value }) => value && <ItemLink>{value}</ItemLink>,
    },
    {
      id: USER_FIELDS.name,
      accessor: 'name',
      gridWidth: 'minmax(10rem, 10rem)',
    },
    {
      id: USER_FIELDS.role,
      accessor: 'role',
      gridWidth: 'minmax(10rem, 1fr)',
      Cell: ({ value }) => ROLE_TYPE[value],
    },
    {
      id: USER_FIELDS.state,
      accessor: 'disabled',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => (
        <ItemBadge colorScheme={TYPE_COLOR_DISABLED[value]}>{TYPE_DISABLED[value]}</ItemBadge>
      ),
    },
  ]
    .filter(({ id }) => {
      if (isMobile && id === 'icon') {
        return false
      }

      return true
    })
    .map((item) => {
      const translateId = USER_FIELDS_TRANSLATION[item.id]

      if (translateId) {
        return { ...item, Header: getTranslation(translateId) }
      }

      return item
    })
}
