import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FilterFooter, FilterHeader, FilterStack } from 'chakra-lib/layout'
import { useSelector } from 'react-redux'
import { getFilterParams } from 'chakra-lib/utils'
import { getURLFromFilterParams } from 'chakra-lib/utils'
import { InputSearch } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { Button, Text } from '@chakra-ui/react'
import { FILTER_USER_PARAMS } from 'config/user.config'

export const UserListFilter = ({ params }) => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const { text } = useSelector((state) => state.user.filter)
  const countList = useSelector((state) => state.user.totalItems)
  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0]
    const filedValue = param[fieldName]
    const objFilterParams = getFilterParams(FILTER_USER_PARAMS, params)
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue,
    })
    history.push(`/laboratory/user${urlFilterParams}`)
  }

  const handleResetFilter = () => {
    history.push(`/laboratory/user`)
  }

  return (
    <>
      <FilterHeader>
        <Button
          width="100%"
          onClick={() => history.push(`/laboratory/user/new`)}
          colorScheme="blue"
        >
          {getTranslation('test.action.buttonCreate')}
        </Button>
      </FilterHeader>
      <FilterStack>
        <InputSearch
          label={getTranslation('global.search')}
          name="text"
          value={text}
          onChange={(e) => handleFilter({ text: e })}
        />
      </FilterStack>
      <FilterFooter>
        <Button size="sm" width="100%" onClick={handleResetFilter}>
          Очистить фильтр
        </Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
    </>
  )
}
