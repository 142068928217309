import React, { useContext } from 'react'
import { Button, useDisclosure, Box, IconButton } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { TranslationContext } from 'translation'
import { getAccess, Private } from 'components/Private'
import { ROLES } from 'model/roles'
import { InputForm, Modal } from 'chakra-lib'
import { Form, FormSection } from 'chakra-lib/layout'
import { EditIcon } from '@chakra-ui/icons'

const formSchema = Yup.object().shape({
  telephone: Yup.string().required('Required'),
})

export const FieldTelephone = ({ item, updateItem }) => {
  const { getTranslation } = useContext(TranslationContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector((state) => state.authentication.user)
  const loadingUpdate = useSelector((state) => state.test.loadingUpdate)

  const handleForm = async (values) => {
    const dataRequest = {
      ...item,
      telephone: values.telephone,
    }
    updateItem(dataRequest)
    onClose()
  }

  return (
    <>
      {item.telephone ? (
        <Box role="group" display="flex" alignItems="center">
          {item.telephone}
          <Private
            role={user.role}
            hasAnyRole={[ROLES.User, ROLES.UserCreator, ROLES.UserPrivileged]}
          >
            <IconButton
              onClick={onOpen}
              aria-label="Edit"
              icon={<EditIcon />}
              variant="ghost"
              opacity={0}
              size="sm"
              tabIndex="none"
              ml={2}
              _groupHover={{
                opacity: 1,
              }}
              _focus={{
                boxShadow: 'none',
              }}
            />
          </Private>
        </Box>
      ) : (
        <Button
          variant="link"
          onClick={onOpen}
          colorScheme="blue"
          isDisabled={!getAccess(user.role, [ROLES.User, ROLES.UserCreator, ROLES.UserPrivileged])}
        >
          {getTranslation('global.add')}
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={
          item.telephone
            ? getTranslation('test.heading.updateTelephone')
            : getTranslation('test.heading.addTelephone')
        }
      >
        <Formik
          initialValues={{
            telephone: item.telephone,
          }}
          onSubmit={handleForm}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {(props) => (
            <Form dataForm={props} onSubmit={props.handleSubmit}>
              <FormSection>
                <Field
                  autoFocus
                  name="telephone"
                  label={getTranslation('test.field.telephone.label')}
                  component={InputForm}
                  placeholder={getTranslation('test.field.telephone.placeholder')}
                />
              </FormSection>
              <FormSection mb="1.25rem">
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!props.dirty}
                  isLoading={loadingUpdate}
                >
                  {item.telephone ? getTranslation('global.update') : getTranslation('global.add')}
                </Button>
              </FormSection>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
