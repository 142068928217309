import React, { useContext, useEffect } from 'react';
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../chakra-lib/layout';
import { Snippets, SnippetsItem, SnippetsList, SnippetsHeading } from '../../../../chakra-lib';
import { TranslationContext } from '../../../../translation';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconGeo } from '../../../../components/icons';
import { Box, Text } from '@chakra-ui/react';
import { truncate } from '../../../../components/deprecated/utilities/truncate';
import {
  DATE_FORMAT,
} from '../../../../components/deprecated/utilities/date-formatting';
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from '../../../../chakra-lib/Snippets';
import {
  getNextPage,
  resetFilter, resetMedicalFacilityList,
  updateFilter
} from '../../../../store/medicalFacility.reducer';
import moment from 'moment';
import { getFilterParams } from '../../../../chakra-lib/utils';
import { MedicalFacilityListFilter } from './elemenst/MedicalFacilityListFilter';
import { FILTER_MEDICAL_FACILITY_PARAMS } from '../../../../config/medicalFacility.config';
import { ROLES } from '../../../../model/roles';

const TYPE_COLOR_RESULT = {
  'true': 'red',
  'false': 'green'
};

export const MedicalFacilityList = () => {
  const {getTranslation} = useContext(TranslationContext);
  const userAuth = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();
  const {params} = useParams();
  const activePage = useSelector(state => state.medicalFacility.activePage);
  const items = useSelector(state => state.medicalFacility.items);
  const totalPages = useSelector(state => state.medicalFacility.totalPages);

  const TYPE_DISABLED = {
    'true': getTranslation('disabled.true'),
    'false': getTranslation('disabled.false')
  };

  const getUrl = (item) => {
    if (userAuth.role === ROLES.Administrator) {
      return `/vaccination/medical-facility/edit/${item.id}`
    }
    return `/vaccination/medical-facility/show/${item.id}`
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_MEDICAL_FACILITY_PARAMS, params);
      dispatch(updateFilter(objFilterParams));
    } else {
      dispatch(updateFilter());
    }
  }, [params]);
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
      dispatch(resetMedicalFacilityList());
    }
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="2.625rem minmax(200px, 1fr) 320px 160px"
            itemWidth="1100px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>{getTranslation('office.snippets.heading.name')}</HeadingItem>
              <HeadingItem>{getTranslation('office.snippets.heading.createdDate')}</HeadingItem>
              <HeadingItem>{getTranslation('office.snippets.heading.state')}</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={items}
              onNext={() => dispatch(getNextPage())}
            >
              {(item, index) => (
                <SnippetsItem
                  to={getUrl(item)}
                >
                  <ItemIcon icon={IconGeo}/>
                  <ItemLink>
                    <Box> {truncate(item.title?.ru, 50)}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {truncate(item?.shortTitle?.ru, 50)}
                    </Text>
                  </ItemLink>
                  <ItemText>{item.createdAt ? moment(item.createdAt).format(DATE_FORMAT) : null}</ItemText>
                  <ItemText>
                    <ItemBadge
                      colorScheme={TYPE_COLOR_RESULT[item.disabled]}
                    >
                      {TYPE_DISABLED[item.disabled]}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <MedicalFacilityListFilter params={params}/>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
