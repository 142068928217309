import { api } from 'api'
import { useQuery } from 'components/useQuery'
import { useCallback, useMemo } from 'react'
import { addressToText } from 'components/Geolocation/api/adapters'

const defaultParams = {
  addressdetails: 1,
  limit: 100,
}

export const useNominationList = (initialParams) => {
  const { response, request, ...state } = useQuery(async (params) => {
    return await api.nomination.search({ ...defaultParams, ...initialParams, ...params })
  })

  const mappedResponse = useMemo(() => {
    if (response?.length) {
      return response
        .filter(({ category }) => ['building', 'way', 'highway'].includes(category))
        .map((item) => {
          return {
            value: addressToText(item.address),
            option: item.display_name,
            data: item,
          }
        })
    }

    return []
  }, [response])

  const fetchList = useCallback((value) => {
    if (value) {
      request({ ...initialParams, q: value })
    }
  }, [])

  return {
    ...state,
    response: mappedResponse,
    request: fetchList,
  }
}

