import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Image, Box, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Logo from '../../images/logo512.png';
import { ContainerApp, Header, NavigationMenuItem } from '../../chakra-lib/layout';
import { ProfileWidget } from '../../components/ProfileWidget';
import { isEmpty } from 'lodash';
import { Private, PrivateRoute } from '../../components/Private';
import { ROLES } from '../../model/roles';
import { LoadingSettings } from '../../components/deprecated/layout';
import { Login } from '../../login';
import { TranslationContext } from '../../translation';
import List from './Vaccination';
import User from './User';
import Vaccine from './Vaccine';
import MedicalFacility from './MedicalFacility';

export const Vaccination = () => {
  const {getTranslation} = useContext(TranslationContext);
  const userAuth = useSelector(state => state.authentication.user);
  const version = useSelector(state => state.settings.version);
  const doneLoading = true;
  const {url} = useRouteMatch();
  const panelName = 'Vaccination';
  const isAuthenticated = !isEmpty(userAuth);
  const versionBack = useSelector(state => state.settings.versionBack);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isAuthenticated) {
      //dispatch(getVaccineList());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.querySelector('html').style.overflow = 'hidden';
  }, []);

  return (
    <>
      {isAuthenticated ? (
        doneLoading ? (
          <>
            <Helmet>
              <title>{`COVID ${panelName}`}</title>
            </Helmet>
            <ContainerApp>
              <Header
                renderLogo={
                  <>
                    <Image src={Logo} width="2.75rem" height="2.75rem" alt=""/>
                    <Box ml={3} lineHeight="1.4">
                      <Box>
                        <Text fontWeight="semibold" fontSize="1.1rem">{panelName}</Text>
                        <Text fontSize="0.5rem" color="gray.400">{`front: ${version} back: ${versionBack}`}</Text>
                      </Box>
                    </Box>
                  </>
                }
                renderNavigationItem={
                  <>
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[ROLES.Administrator, ROLES.UserEditor, ROLES.UserCreator]}
                    >
                      <NavigationMenuItem to={`/vaccination/list`}>
                        Вакцинация
                      </NavigationMenuItem>
                    </Private>
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[ROLES.Administrator, ROLES.UserEditor]}
                    >
                      <NavigationMenuItem to={`/vaccination/medical-facility`}>
                        Медицинские учреждения
                      </NavigationMenuItem>
                    </Private>
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[ROLES.Administrator, ROLES.UserEditor, ROLES.UserCreator]}
                    >
                      <NavigationMenuItem to={`/vaccination/vaccine`}>
                        Вакцины
                      </NavigationMenuItem>
                    </Private>
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[ROLES.Administrator]}
                    >
                      <NavigationMenuItem to={`/vaccination/user`}>
                        {getTranslation('menu.user')}
                      </NavigationMenuItem>
                    </Private>
                  </>
                }
                renderProfileWidget={<ProfileWidget/>}
              />
              <Switch>
                <PrivateRoute
                  path={`${url}/list`}
                  role={userAuth.role}
                  hasAnyRole={[ROLES.Administrator, ROLES.UserEditor, ROLES.UserCreator]}
                >
                  <List/>
                </PrivateRoute>
                <PrivateRoute
                  path={`${url}/medical-facility`}
                  role={userAuth.role}
                  hasAnyRole={[ROLES.Administrator, ROLES.UserEditor]}
                >
                  <MedicalFacility/>
                </PrivateRoute>
                <PrivateRoute
                  path={`${url}/vaccine`}
                  role={userAuth.role}
                  hasAnyRole={[ROLES.Administrator, ROLES.UserEditor, ROLES.UserCreator]}
                >
                  <Vaccine/>
                </PrivateRoute>
                <PrivateRoute
                  path={`${url}/user`}
                  role={userAuth.role}
                  hasAnyRole={[ROLES.Administrator]}
                >
                  <User/>
                </PrivateRoute>
                <Route path={`/`}>
                  <Private
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator, ROLES.User, ROLES.UserLab, ROLES.UserCreator, ROLES.UserResultSetter]}
                  >
                    <Redirect to="/vaccination/list"/>
                  </Private>
                </Route>
              </Switch>
            </ContainerApp>
          </>
        ) : (
          <LoadingSettings/>
        )
      ) : (
        <Login/>
      )}
    </>
  );
};
