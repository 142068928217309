import React, { useContext, useEffect } from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
  HeadingContainer,
  RightAside,
} from 'chakra-lib/layout'
import { SymbolLine } from 'components/symbol-line'
import { Private } from 'components/Private'
import { ROLES } from 'model/roles'
import { TranslationContext } from 'translation'
import { Text } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { WidgetTestResult } from 'components/test-result'
import { getTest, resetTest } from 'store/test.reducer'
import { getOffice, resetOffice } from 'store/office.reducer'
import { getDateReadFormat, getTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import { ContentDate } from 'components/content-date'
import { Heading } from 'chakra-lib'
import { isEmpty } from 'lodash'

export const TestCard = () => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const user = useSelector((state) => state.authentication.user)
  const item = useSelector((state) => state.test.item)
  const itemOffice = useSelector((state) => state.office.item)
  const loadingOffice = useSelector((state) => state.office.loading)
  const loading = useSelector((state) => state.test.loading)
  const isReadyItem = !isEmpty(item)
  const isReadyItemOffice = !isEmpty(itemOffice)

  useEffect(() => {
    dispatch(getTest(id))
    return () => {
      dispatch(resetTest())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(item) && user.role === ROLES.Administrator && item.officeId) {
      dispatch(getOffice(item.officeId))
    }
    return () => {
      dispatch(resetOffice())
    }
  }, [item])
  const handleBack = () => {
    history.goBack()
  }

  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">{`${getTranslation('test.heading.show')} №${
                  item.internalId ? item.internalId : ''
                }`}</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading>{getTranslation('test.section.heading.client')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('test.field.passport.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.passport && <SymbolLine>{item.passport}</SymbolLine>}
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>{getTranslation('test.section.heading.test')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('test.field.testType.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.testTypeTitle && <SymbolLine>{item.testTypeTitle.ru}</SymbolLine>}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.internalId.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.internalId && <SymbolLine>{item.internalId}</SymbolLine>}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.testingDate.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.testingDate &&
                    `${getDateReadFormat(item.testingDate)}, ${getTimeReadFormat(
                      item.testingDate
                    )}`}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.resultDate.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.resultDate &&
                    `${getDateReadFormat(item.resultDate)}, ${getTimeReadFormat(item.resultDate)}`}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.resultSetDate.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.resultSetDate &&
                    `${getDateReadFormat(item.resultSetDate)}, ${getTimeReadFormat(
                      item.resultSetDate
                    )}`}
                </GridFieldItem>
              </GridField>
              <Private role={user.role} hasAnyRole={[ROLES.Administrator]}>
                <GridField>
                  <GridFieldHeading>
                    {getTranslation('test.section.heading.office')}
                  </GridFieldHeading>
                  <GridFieldItem
                    label={getTranslation('office.field.titleRu.label')}
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {itemOffice.title?.ru}
                  </GridFieldItem>
                  <GridFieldItem
                    label={getTranslation('office.field.titleEn.placeholder')}
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {itemOffice.title?.en}
                  </GridFieldItem>
                  <GridFieldItem
                    label={getTranslation('office.field.address.label')}
                    isLoading={loadingOffice}
                    isReady={isReadyItemOffice}
                  >
                    {itemOffice.address && itemOffice.address.ru}
                  </GridFieldItem>
                </GridField>
              </Private>
            </ContentContainerColumn>
            <ContentContainerColumn>
              <RightAside>
                <Text fontSize="0.9rem" color="gray.400" marginBottom="0.625rem">
                  {getTranslation('test.aside.resultTest')}
                </Text>
                <WidgetTestResult item={item} isLoading={loading} />
                <ContentDate item={item} loading={loading} />
              </RightAside>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
    </>
  )
}
