import React, { useContext, useEffect, useState } from 'react'
import { TranslationContext } from 'translation'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, useToast } from '@chakra-ui/react'
import { createUser, getUser, resetUser, updateUser } from 'store/user.reducer'
import { getOfficeList, resetOfficeList } from 'store/office.reducer'
import { Alert, CheckboxForm, Heading, InputForm, SelectForm } from 'chakra-lib'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormSection,
  HeadingContainer,
} from 'chakra-lib/layout'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { ROLES } from 'model/roles'

const formSchemaNew = Yup.object().shape({
  name: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
})

const formSchemaEdit = Yup.object().shape({
  name: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
})

const allowedOffice = (role) => {
  return [ROLES.User, ROLES.UserCreator, ROLES.UserPrivileged, ROLES.UserResultSetter].includes(
    role
  )
}

export const UserUpdate = () => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const toast = useToast()

  const [isNew] = useState(!id)
  const item = useSelector((state) => state.user.item)
  const loading = useSelector((state) => state.user.loading)
  const loadingUpdate = useSelector((state) => state.user.loadingUpdate)
  const officeList = useSelector((state) => state.office.items)
  const officeLoading = useSelector((state) => state.office.loading)
  const errorMessage = useSelector((state) => state.user.errorMessage)
  const userAuth = useSelector((state) => state.authentication.user)
  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (isNew) {
      dispatch(resetUser())
    } else {
      dispatch(getUser(id))
    }
    return () => {
      dispatch(resetUser())
    }
  }, [])

  useEffect(() => {
    dispatch(getOfficeList())
    return () => {
      dispatch(resetOfficeList())
    }
  }, [])

  const handleForm = async (values) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      name: values.name,
      username: values.username,
      password: values.password ? values.password : undefined,
      role: values.role,
      officeId: allowedOffice(values.role) ? values.officeId : undefined,
      disabled: values.disabled,
    }
    if (isNew) {
      await dispatch(createUser(dataRequest))
      toast({
        title: getTranslation('user.toast.newUser.success.title'),
        description: `${values.name} ${getTranslation('user.toast.newUser.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack()
    } else {
      await dispatch(updateUser(dataRequest))
      toast({
        title: getTranslation('user.toast.updateUser.success.title'),
        description: `${values.name} ${getTranslation(
          'user.toast.updateUser.success.description'
        )}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack()
    }
  }
  return (
    <Container>
      <CardContainer onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? getTranslation('user.heading.new') : getTranslation('user.heading.update')}
              </Heading>
            </HeadingContainer>
            {errorMessage && <Alert marginBottom="1.875rem">{errorMessage?.error}</Alert>}
            <Formik
              initialValues={{
                name: item.name,
                username: item.username,
                password: '',
                role: item.role ? item.role : 'User',
                officeId: item.officeId,
                disabled: item.disabled,
              }}
              enableReinitialize={true}
              validationSchema={isNew ? formSchemaNew : formSchemaEdit}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew
                        ? getTranslation('user.action.buttonCreate')
                        : getTranslation('user.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      autoFocus
                      name="name"
                      label={getTranslation('user.field.name.label')}
                      component={InputForm}
                      placeholder={getTranslation('user.field.name.placeholder')}
                      isDisabled={loading}
                    />
                    <FormGrid columns={2}>
                      <Field
                        name="username"
                        label={getTranslation('user.field.username.label')}
                        component={InputForm}
                        placeholder={getTranslation('user.field.username.placeholder')}
                        isDisabled={loading}
                      />
                      <Field
                        name="password"
                        label={getTranslation('user.field.password.label')}
                        type="password"
                        component={InputForm}
                        placeholder={getTranslation('user.field.password.placeholder')}
                        isDisabled={loading}
                      />
                    </FormGrid>
                    <Field
                      name="role"
                      isDisabled={userAuth?.id === item?.id}
                      label={getTranslation('user.field.role.label')}
                      component={SelectForm}
                      placeholder={getTranslation('user.field.role.placeholder')}
                      isSearchable={false}
                      options={[
                        { value: ROLES.Administrator, label: getTranslation('role.administrator') },
                        { value: ROLES.User, label: getTranslation('role.user') },
                        { value: ROLES.UserCreator, label: getTranslation('role.userCreator') },
                        {
                          value: ROLES.UserPrivileged,
                          label: getTranslation('role.userPrivileged'),
                        },
                        {
                          value: ROLES.UserResultSetter,
                          label: getTranslation('role.userResultSetter'),
                        },
                        { value: ROLES.UserLab, label: getTranslation('role.userLab') },
                        { value: ROLES.Api, label: 'Api' },
                      ]}
                    />
                    {allowedOffice(props.values.role) && (
                      <Field
                        name="officeId"
                        label={getTranslation('user.field.officeId.label')}
                        component={SelectForm}
                        placeholder={getTranslation('user.field.officeId.placeholder')}
                        isLoading={officeLoading}
                        options={
                          officeList &&
                          officeList.map((item) => ({
                            value: item.id,
                            label: item.title?.ru,
                          }))
                        }
                      />
                    )}
                    <Field
                      name="disabled"
                      component={CheckboxForm}
                      label={getTranslation('user.field.disabled.label')}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
