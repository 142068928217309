import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Text, VStack } from '@chakra-ui/react';
import { TranslationContext } from 'translation';
import { getFilterParams, getURLFromFilterParams } from 'chakra-lib/utils';
import { FILTER_LABORATORY_PARAMS } from 'config/laboratory.config';
import { FilterFooter, FilterHeader, FilterStack } from 'chakra-lib/layout';
import { InputSearch } from 'chakra-lib';

export const LaboratoryListFilter = ({ params, onExportExcel }) => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const { text } = useSelector(state => state.laboratory.filter);
  const countList = useSelector(state => state.laboratory.totalItems);
  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0];
    const filedValue = param[fieldName];
    const objFilterParams = getFilterParams(FILTER_LABORATORY_PARAMS, params);
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue
    })
    history.push(`/national/laboratory${urlFilterParams}`);
  }

  const handleResetFilter = () => {
    history.push(`/national/laboratory`)
  }

  return (
    <>
      <FilterHeader>
        <VStack spacing="0.625rem" w="100%">
          <Button
            width="100%"
            onClick={() => history.push(`/national/laboratory/new`)}
            colorScheme="blue"
          >
            {getTranslation('test.action.buttonCreate')}
          </Button>
          <Button onClick={onExportExcel} width="100%">Выгрузить в excel</Button>
        </VStack>
      </FilterHeader>
      <FilterStack>
        <InputSearch
          label={getTranslation('global.search')}
          name="text"
          value={text}
          onChange={e => handleFilter({ text: e })}
        />
      </FilterStack>
      <FilterFooter>
        <Button
          size="sm"
          width="100%"
          onClick={handleResetFilter}
        >Очистить фильтр</Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
    </>
  )
};
