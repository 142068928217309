import React, { useEffect, useState } from 'react'
import { lang } from './lang'
import { useLocalStorage } from 'components/deprecated/use-local-storage'

export const TranslationContext = React.createContext({})

export const Translation = ({ children }) => {
  const [localLanguage, setLocalLanguage] = useLocalStorage('language', 'ru')
  const [language, setLanguage] = useState(localLanguage)

  const getTranslation = (key) => {
    if (key) {
      return lang[key][language]
    }
    return ''
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setLocalLanguage(language)
  }, [language])

  return (
    <TranslationContext.Provider
      value={{
        getTranslation,
        language,
        setLanguage,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}
