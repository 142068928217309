import React, { memo, useCallback } from 'react'

import { Box, BoxProps, Flex, Grid, useColorModeValue } from '@chakra-ui/react'

export const TBody: React.FC<BoxProps> = memo(({ children, ...props }) => {
  return (
    <Box {...props}>
      {children}
    </Box>
  )
})

TBody.displayName = 'TBody'

export const BodyRow: React.FC<BoxProps> = memo(({ children, ...props }) => {
  const themeColor = useColorModeValue('black', 'white')
  const themeBorderColor = useColorModeValue('gray.100', 'gray.700')
  const themeBgHover = useColorModeValue('gray.50', 'gray.700')
  const themeBgActive = useColorModeValue('gray.100', 'gray.700')

  return (
    <Grid
      minHeight="3.5rem"
      transition="0.3s"
      borderBottomWidth="1px"
      borderColor={themeBorderColor}
      color={themeColor}
      _hover={{
        backgroundColor: themeBgHover,
      }}
      _active={{
        backgroundColor: themeBgActive,
      }}
      {...props}
    >
      {children}
    </Grid>
  )
})

BodyRow.displayName = 'BodyRow'

export interface BodyCellProps extends BoxProps {
  preventRowClick?: boolean
}

export const BodyCell: React.FC<BodyCellProps> = memo(
  ({ children, onClick, preventRowClick, ...props }) => {
    const hasClickAction = !preventRowClick && onClick

    const onClickHandle = useCallback(
      (e) => {
        if (hasClickAction) {
          onClick && onClick(e)
        }
      },
      [onClick]
    )

    return (
      <Flex
        px="1rem"
        py="0.75rem"
        alignItems="center"
        cursor={hasClickAction ? 'pointer' : 'default'}
        onClick={onClickHandle}
        {...props}
      >
        {children}
      </Flex>
    )
  }
)

BodyCell.displayName = 'BodyCell'
