import axios from 'axios'

export const SEARCH_URL = 'https://nominatim.openstreetmap.org'

const defaultSearchParams = {
  format: 'jsonv2',
  'accept-language': 'ru',
}

const defaultLookupParams = {
  format: 'jsonv2',
  'accept-language': 'ru',
}

const defaultReversParams = {
  format: 'jsonv2',
  'accept-language': 'ru',
}

const defaultDetailsParams = {
  format: 'json',
  'accept-language': 'ru',
}

export const nomination = Object.freeze({
  async search(params) {
    const response = await axios.request({
      url: `${SEARCH_URL}/search`,
      method: 'GET',
      params: { ...defaultSearchParams, ...params },
    })

    return response.data
  },
  async lookup(params) {
    const response = await axios.request({
      url: `${SEARCH_URL}/lookup`,
      method: 'GET',
      params: { ...defaultLookupParams, ...params },
    })

    return response.data
  },
  async reverse(params) {
    const response = await axios.request({
      url: `${SEARCH_URL}/reverse`,
      method: 'GET',
      params: { ...defaultReversParams, ...params },
    })

    return response.data
  },
  async details(params) {
    const response = await axios.request({
      url: `${SEARCH_URL}/details`,
      method: 'GET',
      params: { ...defaultDetailsParams, ...params },
    })

    return response.data
  },
})
