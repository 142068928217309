const TOKEN = '729b21efbde6763624c5416de4690e2f4027d20f'

const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Token ${TOKEN}`,
}

const OPTIONS = {
  address: {
    count: 10,
    locations: [{ country_iso_code: 'ru' }, { country_iso_code: 'am' }],
  },
  fio: {},
}

export const options = (query, option = null) => ({
  method: 'POST',
  mode: 'cors',
  headers: HEADERS,
  body: JSON.stringify({
    query: query,
    ...(option && OPTIONS[option]),
  }),
})
