import { FAILURE, REQUEST, SUCCESS } from './actions-type'
import axios from 'axios'
import { API_SERVER_URL } from './config'
import { checkParam } from 'chakra-lib/utils'

const PAGE_SIZE = 20
const START_PAGE = 1

export const ACTION_TYPES = {
  FETCH_LABORATORY_LIST: 'laboratory/FETCH_LABORATORY_LIST',
  RESET_LABORATORY_LIST: 'laboratory/RESET_LABORATORY_LIST',
  FETCH_LABORATORY: 'laboratory/FETCH_LABORATORY',
  CREATE_LABORATORY: 'laboratory/CREATE_LABORATORY',
  UPDATE_LABORATORY: 'laboratory/UPDATE_LABORATORY',
  FETCH_ACCEPT_LABORATORY: 'laboratory/FETCH_ACCEPT_LABORATORY',
  SET_ACCEPT_LABORATORY: 'laboratory/SET_ACCEPT_LABORATORY',
  DELETE_ACCEPT_LABORATORY: 'laboratory/SET_ACCEPT_LABORATORY',
  UPDATE_FILTER: 'laboratory/UPDATE_FILTER',
  RESET_FILTER: 'laboratory/RESET_FILTER',
  RESET_ACCEPT_LABORATORY: 'laboratory/RESET_ACCEPT_LABORATORY',
  RESET_LABORATORY: 'laboratory/RESET_LABORATORY',
  RESET: 'laboratory/RESET',
}

const initialState = {
  items: [],
  itemsAccept: [],
  item: {},
  loading: false,
  loadingAccept: false,
  loadingUpdate: false,
  errorMessage: '',
  totalItems: 0,
  totalPages: 0,
  activePage: START_PAGE,
  filter: {
    text: '',
    laboratoryId: '',
  },
}

export const laboratoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LABORATORY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_LABORATORY): {
      return {
        ...state,
        loading: true,
      }
    }
    case REQUEST(ACTION_TYPES.CREATE_LABORATORY):
    case REQUEST(ACTION_TYPES.UPDATE_LABORATORY): {
      return {
        ...state,
        errorMessage: '',
        loadingUpdate: true,
      }
    }
    case REQUEST(ACTION_TYPES.DELETE_ACCEPT_LABORATORY):
    case REQUEST(ACTION_TYPES.SET_ACCEPT_LABORATORY):
    case REQUEST(ACTION_TYPES.FETCH_ACCEPT_LABORATORY): {
      return {
        ...state,
        loadingAccept: true,
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_LABORATORY_LIST): {
      return {
        ...state,
        loading: false,
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_LABORATORY):
    case FAILURE(ACTION_TYPES.CREATE_LABORATORY):
    case FAILURE(ACTION_TYPES.UPDATE_LABORATORY): {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.response.data,
        loadingUpdate: false,
      }
    }
    case FAILURE(ACTION_TYPES.DELETE_ACCEPT_LABORATORY):
    case FAILURE(ACTION_TYPES.SET_ACCEPT_LABORATORY):
    case FAILURE(ACTION_TYPES.FETCH_ACCEPT_LABORATORY): {
      return {
        ...state,
        loadingAccept: false,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_LABORATORY_LIST): {
      const navigation = JSON.parse(action.payload.headers['x-pagination'])
      const items =
        navigation['CurrentPage'] === 1
          ? action.payload.data
          : state.items.concat(action.payload.data)
      return {
        ...state,
        loading: false,
        items,
        totalItems: navigation['TotalCount'],
        totalPages: navigation['TotalPages'],
        activePage: navigation['CurrentPage'],
        errorMessage: '',
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_LABORATORY): {
      return {
        ...state,
        errorMessage: '',
        item: action.payload.data,
        loading: false,
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_LABORATORY): {
      return {
        ...state,
        errorMessage: '',
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_LABORATORY): {
      return {
        ...state,
        errorMessage: '',
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ACCEPT_LABORATORY): {
      return {
        ...state,
        itemsAccept: action.payload.data,
        loadingAccept: false,
      }
    }
    case SUCCESS(ACTION_TYPES.SET_ACCEPT_LABORATORY): {
      return {
        ...state,
        itemsAccept: action.payload.data,
        loadingAccept: false,
      }
    }
    case SUCCESS(ACTION_TYPES.DELETE_ACCEPT_LABORATORY): {
      return {
        ...state,
        itemsAccept: action.payload.data,
        loadingAccept: false,
      }
    }
    case ACTION_TYPES.UPDATE_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
          ...action.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_ACCEPT_LABORATORY: {
      return {
        ...state,
        itemsAccept: [],
        loadingAccept: false,
      }
    }
    case ACTION_TYPES.RESET_LABORATORY_LIST: {
      return {
        ...state,
        items: [],
        loading: false,
        totalItems: 0,
        totalPages: 0,
        activePage: START_PAGE,
        loadingUpdate: false,
      }
    }
    case ACTION_TYPES.RESET_LABORATORY: {
      return {
        ...state,
        item: {},
        loading: false,
        errorMessage: '',
        loadingUpdate: false,
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...state,
        items: [],
        itemsAccept: [],
        item: {},
        loading: false,
        loadingAccept: false,
        loadingUpdate: false,
      }
    }
    default:
      return state
  }
}

const API_URL = `${API_SERVER_URL}/laboratory`

export const getLaboratoryList = () => {
  const requestUrl = `${API_URL}?pageSize=${10000}&page=${1}`
  return {
    type: ACTION_TYPES.FETCH_LABORATORY_LIST,
    payload: axios.get(requestUrl),
  }
}

export const getLaboratoryListNavigation = (dataRequest) => {
  const { pageSize, page } = dataRequest
  const requestUrl = `${API_URL}/?pageSize=${pageSize}&page=${page}`
  return {
    type: ACTION_TYPES.FETCH_LABORATORY_LIST,
    payload: axios.get(requestUrl),
  }
}

export const getLaboratoryListSearch = (page) => (dispatch, getState) => {
  const filter = getState().laboratory.filter
  const requestUrl = `${API_URL}/findtext?pageSize=${PAGE_SIZE}&page=${page}`
  dispatch({
    type: ACTION_TYPES.FETCH_LABORATORY_LIST,
    payload: axios.post(requestUrl, {
      text: checkParam(filter.text),
    }),
  })
}

export const updateFilter = (params) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_FILTER,
    filter: {
      ...params,
    },
  })
  dispatch(getLaboratoryListSearch(START_PAGE))
}

export const getNextPage = () => (dispatch, getState) => {
  const activePage = getState().laboratory.activePage
  dispatch(getLaboratoryListSearch(activePage + 1))
}

export const getLaboratory = (id) => {
  const requestUrl = `${API_URL}/${id}`
  return {
    type: ACTION_TYPES.FETCH_LABORATORY,
    payload: axios.get(requestUrl),
  }
}

export const createLaboratory = (dataRequest) => {
  const requestUrl = `${API_URL}`
  return {
    type: ACTION_TYPES.CREATE_LABORATORY,
    payload: axios.post(requestUrl, {
      ...dataRequest,
    }),
  }
}

export const updateLaboratory = (dataRequest) => {
  const requestUrl = `${API_URL}`
  return {
    type: ACTION_TYPES.UPDATE_LABORATORY,
    payload: axios.put(requestUrl, {
      ...dataRequest,
    }),
  }
}

export const getAcceptLaboratory = (id) => {
  const requestUrl = `${API_URL}/${id}/acceptby`
  return {
    type: ACTION_TYPES.FETCH_ACCEPT_LABORATORY,
    payload: axios.get(requestUrl),
  }
}

export const setAcceptLaboratory = (id, dataRequest) => {
  const requestUrl = `${API_URL}/${id}/acceptby`
  return {
    type: ACTION_TYPES.SET_ACCEPT_LABORATORY,
    payload: axios.put(requestUrl, {
      ...dataRequest,
    }),
  }
}

export const deleteAcceptLaboratory = (id, dataRequest) => {
  const requestUrl = `${API_URL}/${id}/acceptby/delete`
  return {
    type: ACTION_TYPES.DELETE_ACCEPT_LABORATORY,
    payload: axios.post(requestUrl, {
      ...dataRequest,
    }),
  }
}

export const resetLaboratoryList = () => {
  return {
    type: ACTION_TYPES.RESET_LABORATORY_LIST,
  }
}

export const resetAcceptLaboratory = () => {
  return {
    type: ACTION_TYPES.RESET_ACCEPT_LABORATORY,
  }
}

export const resetFilter = () => {
  return {
    type: ACTION_TYPES.RESET_FILTER
  };
};

export const resetLaboratory = () => {
  return {
    type: ACTION_TYPES.RESET_LABORATORY
  };
};

export const reset = () => {
  return {
    type: ACTION_TYPES.RESET
  };
};
