import React, { useContext } from 'react'

import { Box, Button, Flex, Text, useBoolean, useColorModeValue } from '@chakra-ui/react'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { Drawer } from 'chakra-lib/Drawer'
import { TranslationContext } from 'translation'
import { Search2Icon } from '@chakra-ui/icons'

const Register = ({ children, title, rightPanel }) => {
  const { getTranslation } = useContext(TranslationContext)
  const themeBorderColor = useColorModeValue('gray.100', 'gray.700')
  const [isOpenRightPanel, setOpenRightPanel] = useBoolean()
  const btnRef = React.useRef()

  return (
    <Flex h="100%" direction={isMobile ? 'column' : 'row'}>
      <MobileView>
        {rightPanel && (
          <Flex h="4rem" px="1rem" alignItems="center" justifyContent="space-between">
            <Text textStyle="h5">{title}</Text>
            <Button
              display="flex"
              leftIcon={<Search2Icon />}
              ref={btnRef}
              variant="unstyled"
              onClick={setOpenRightPanel.on}
              borderRadius={0}
              h="100%"
            >
              {getTranslation('global.table.search')}
            </Button>
            <Drawer
              isOpen={isOpenRightPanel}
              onClose={setOpenRightPanel.off}
              finalFocusRef={btnRef}
            >
              {rightPanel}
            </Drawer>
          </Flex>
        )}
      </MobileView>
      <Box overflow="hidden" flexGrow={1} borderRightWidth="1px" borderColor={themeBorderColor}>
        {children}
      </Box>
      <BrowserView>
        {rightPanel && (
          <Box w="290px" minW="290px">
            {rightPanel}
          </Box>
        )}
      </BrowserView>
    </Flex>
  )
}

export default Register