import React from 'react'

import { Text } from 'chakra-lib'
import { ItemIcon } from 'chakra-lib/Snippets'
import TestingDate from 'pages/laboratory/Test/TestList/Cells/TestingDate'
import { ItemTestResult } from './Cells/ItemTestResult'
import { IconPersonLines } from 'components/icons'
import { ROLES } from 'model/roles'
import { TEST_FIELDS, TEST_FIELDS_TRANSLATION } from '../Test.constants'
import { getDateReadFormat, getTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import { isMobile } from 'react-device-detect'

export const makeColumns = ({ getTranslation, role }) =>
  [
    {
      id: 'icon',
      accessor: 'id',
      gridWidth: '3.125rem',
      Cell: () => <ItemIcon icon={IconPersonLines} />,
    },
    {
      id: TEST_FIELDS.fio,
      accessor: 'fio',
      gridWidth: 'minmax(12rem, 1fr)',
      NoDataCell: () => (
        <Text color="orange.400">{getTranslation('test.snippets.item.fioEmpty')}</Text>
      ),
    },
    {
      id: TEST_FIELDS.passport,
      accessor: 'passport',
      gridWidth: 'minmax(10rem, 10rem)',
    },
    {
      id: TEST_FIELDS.telephone,
      accessor: 'telephone',
      gridWidth: 'minmax(10rem, 10rem)',
    },
    {
      id: TEST_FIELDS.email,
      accessor: 'email',
      gridWidth: 'minmax(12rem, 12rem)',
    },
    {
      id: TEST_FIELDS.officeTitleRu,
      accessor: ({ officeTitle }) => officeTitle?.ru,
      gridWidth: 'minmax(10rem, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.officeTitleEn,
      accessor: ({ officeTitle }) => officeTitle?.en,
      gridWidth: 'minmax(10rem, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.officeAddress,
      accessor: ({ officeAddress }) => officeAddress?.ru,
      gridWidth: 'minmax(10rem, 10rem)',
    },
    {
      id: TEST_FIELDS.internalId,
      accessor: 'internalId',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.testType,
      accessor: ({ testTypeTitle }) => testTypeTitle?.ru,
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.testingDate,
      accessor: 'testingDate',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => <TestingDate testingDate={value} />,
    },
    {
      id: TEST_FIELDS.resultDate,
      accessor: 'resultDate',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => `${getDateReadFormat(value)}, ${getTimeReadFormat(value)}`,
    },
    {
      id: TEST_FIELDS.resultSetDate,
      accessor: 'resultSetDate',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => `${getDateReadFormat(value)}, ${getTimeReadFormat(value)}`,
    },
    {
      id: TEST_FIELDS.createdAt,
      accessor: 'createdAt',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ value }) => `${getDateReadFormat(value)}, ${getTimeReadFormat(value)}`,
    },
    {
      id: TEST_FIELDS.result,
      accessor: 'id',
      gridWidth: 'minmax(10rem, 10rem)',
      Cell: ({ row: { original } }) => {
        return (
          <ItemTestResult
            invalid={original.invalid}
            result={original.result}
            testingDate={original.testingDate}
          />
        )
      },
    },
  ]
    .map(mapItemHeader({ getTranslation }))
    .filter(({ id }) => {
      if (isMobile && id === 'icon') {
        return false
      }

      if (id === TEST_FIELDS.officeTitleRu) {
        return [ROLES.Administrator, ROLES.UserLab].includes(role)
      }

      return true
    })

export const mapItemHeader =
  ({ getTranslation }) =>
  (item) => {
    const translateId = TEST_FIELDS_TRANSLATION[item.id]

    if (translateId) {
      let translate = getTranslation(translateId)
      const isOfficeField = [
        TEST_FIELDS.officeTitleRu,
        TEST_FIELDS.officeTitleEn,
        TEST_FIELDS.officeAddress,
      ].includes(item.id)

      if (isOfficeField) {
        translate = 'Офис. ' + translate
      }

      return { ...item, Header: translate }
    }

    return item
  }