import React from 'react'
import { Grid } from '@chakra-ui/react'
import { isMobile } from "react-device-detect";

export const ContentContainer = ({ children, ...props }) => {
  return (
    <Grid
      templateColumns={isMobile ? '1fr' : 'minmax(600px, 800px) 300px'}
      gap="3.75rem"
      pb="5rem"
      {...props}
    >
      {children}
    </Grid>
  )
}
