import React from 'react'

import { FormLabel } from 'chakra-lib'
import { FilterStack } from 'chakra-lib/layout'
import { Box, Button, VStack } from '@chakra-ui/react'

import { ReportFull } from './ReportFull'
import { ReportTotal } from './ReportTotal'
import { ReportLaboratory } from './ReportLaboratory'

export const TestListFilter = ({ onExportExcel }) => {
  return (
    <FilterStack>
      <Box>
        <FormLabel>Отчеты</FormLabel>
        <VStack spacing="0.625rem">
          <ReportTotal />
          <ReportFull />
          <ReportLaboratory />
          <Button onClick={onExportExcel} width="100%">
            Выгрузить в excel
          </Button>
        </VStack>
      </Box>
    </FilterStack>
  )
}
