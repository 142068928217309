import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getFilterParams } from 'chakra-lib/utils'
import { getNextPage, resetFilter, resetUserList, updateFilter } from 'store/user.reducer'
import { UserListFilter } from './elements/UserListFilter'
import { FILTER_USER_PARAMS } from 'config/user.config'
import Register from 'components/layout/Register'
import { STORAGE_TABLE } from 'model/localStorage'
import { makeColumns } from './UserList.config'
import { useProfileInfo } from 'components/ProfileWidget'

export const UserList = () => {
  const { getTranslation } = useContext(TranslationContext)
  const { params } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const items = useSelector((state) => state.user.items)
  const activePage = useSelector((state) => state.user.activePage)
  const totalPages = useSelector((state) => state.user.totalPages)
  const loading = useSelector((state) => state.user.loading)
  const userAuth = useSelector((state) => state.authentication.user)
  const { ROLE_TYPE } = useProfileInfo()

  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_USER_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])

  useEffect(() => {
    return () => {
      dispatch(resetFilter())
      dispatch(resetUserList())
    }
  }, [])

  const onRowClick = useCallback(({ original }) => {
    history.push(`/laboratory/user/edit/${original.id}`)
  }, [])

  const columns = useMemo(() => makeColumns({ getTranslation, ROLE_TYPE }), [])

  return (
    <Register title={getTranslation('menu.user')} rightPanel={<UserListFilter params={params} />}>
      <Table
        data={items}
        columns={columns}
        isLoading={loading}
        hasNext={activePage < totalPages}
        onNextPage={() => dispatch(getNextPage())}
        onRowClick={onRowClick}
        storageId={`${STORAGE_TABLE.laboratoryUser}.${userAuth.id}`}
      />
    </Register>
  )
}
