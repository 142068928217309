import React from 'react'
import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import createCache from '@emotion/cache'
import createRgbaHexToFuncPlugin from 'rgba-hex-to-func'
import createCssVarRevertPlugin from 'css-vars-revert'
import { CacheProvider } from '@emotion/react'
import { isMobile } from 'react-device-detect'

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
})

// Create a custom cache with plugins
const ie11StyleAwareCache = createCache({
  key: 'nocovid',
  stylisPlugins: [createCssVarRevertPlugin(), createRgbaHexToFuncPlugin()],
})

const textStyles = {
  version: {
    fontSize: '0.6rem',
    color: 'gray.400',
  },
  caption: {
    fontSize: '0.75rem',
    color: '#718096',
  },
  h4: {
    fontWeight: 'semibold',
    fontSize: '1.1rem',
  },
  h5: {
    fontWeight: 'semibold',
    fontSize: '1rem',
  },
  h6: {
    color: "gray.400",
    fontSize: '0.9rem',
  },
}

export const theme = extendTheme({
  textStyles,
  breakpoints,
})

export const mobileTheme = extendTheme({
  breakpoints,
  textStyles,
  styles: {
    global: {
      html: {
        fontSize: '2.5rem',
      },
    },
  },
})

export const Theme = ({ children }) => {
  return (
    <CacheProvider value={ie11StyleAwareCache}>
      <ChakraProvider theme={isMobile ? mobileTheme : theme}>{children}</ChakraProvider>
    </CacheProvider>
  )
}
