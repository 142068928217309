import React from 'react'
import moment from 'moment'

import { Text } from 'chakra-lib'
import { ItemIcon } from 'chakra-lib/Snippets'
import { IconList } from 'components/icons'

import { TEST_TYPE_FIELDS, TEST_TYPE_FIELDS_TRANSLATION } from '../TestType.constants'
import { Actions } from './Cells'
import { DATE_FORMAT } from 'utils/dateFormatting'

export const makeColumns = ({ getTranslation, onDelete, isUpdating }) => {
  return [
    {
      id: 'icon',
      accessor: 'id',
      gridWidth: '3.125rem',
      Cell: () => <ItemIcon icon={IconList} />,
    },
    {
      id: TEST_TYPE_FIELDS.title,
      accessor: ({ title }) => title.ru,
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_TYPE_FIELDS.shortTitle,
      accessor: ({ shortTitle }) => shortTitle.ru,
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_TYPE_FIELDS.createdAt,
      accessor: ({ createdAt }) => moment(createdAt).format(DATE_FORMAT),
      gridWidth: 180,
    },
    {
      id: TEST_TYPE_FIELDS.actions,
      accessor: 'id',
      gridWidth: 150,
      Cell: ({ row }) => (
        <Actions
          isActive={row.original.disabled}
          isDisabled={isUpdating}
          onDelete={() => onDelete(row)}
        />
      ),
      preventRowClick: true,
    },
  ].map((item) => {
    const translateId = TEST_TYPE_FIELDS_TRANSLATION[item.id]

    if (translateId) {
      return { ...item, Header: getTranslation(translateId) }
    }

    return item
  })
}