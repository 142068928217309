import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { Box, Button, useBoolean } from '@chakra-ui/react'
import Register from 'components/layout/Register'

import { useTestTypeList } from '../_services'
import { makeColumns } from './TestTypeList.config'
import TestTypeCard from '../TestTypeCard'

const TestTypeList = () => {
  const tableRef = useRef()
  const [cardData, setCardData] = useState()
  const [cardIsOpen, setOpenCard] = useBoolean()
  const { getTranslation } = useContext(TranslationContext)
  const { data, fetchList, updateItem, createItem, isLoading, isUpdating, next } = useTestTypeList()
  const formIsNew = !cardData

  useEffect(() => {
    fetchList()
  }, [])

  const onRowClick = useCallback(({ original }) => {
    setCardData(original)
    setOpenCard.on()
  }, [])

  const onCloseCard = useCallback(() => {
    setCardData()
    setOpenCard.off()
  }, [])

  const onDeleteHandle = useCallback(({ original }) => {
    updateItem({
      ...original,
      disabled: !original.disabled,
    })
  }, [])

  const onSubmitFormHandle = useCallback((values) => {
    if(formIsNew) {
      createItem(values)
    } else {
      updateItem(values)
    }

    setOpenCard.off()
  }, [formIsNew])

  const columns = useMemo(
    () => makeColumns({ getTranslation, isUpdating, onDelete: onDeleteHandle }),
    [isUpdating]
  )

  return (
    <>
      <Register
        rightPanel={
          <Box px="1.625rem" pt="1.875rem">
            <Button width="100%" onClick={setOpenCard.on} colorScheme="blue">
              {getTranslation('test.action.buttonCreate')}
            </Button>
          </Box>
        }
      >
        <Table
          ref={tableRef}
          data={data?.list || []}
          columns={columns}
          isLoading={isLoading}
          hasNext={data?.hasNext}
          onNextPage={next}
          onRowClick={onRowClick}
        />
      </Register>
      <TestTypeCard
        data={cardData}
        isOpen={cardIsOpen}
        onClose={onCloseCard}
        onSubmit={onSubmitFormHandle}
      />
    </>
  )
}

export default TestTypeList