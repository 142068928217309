import React, { useCallback, useState } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

const MenuGroup = ({ options, onChange, defaultValue, title }) => {
  const [state, setState] = useState(defaultValue)

  const onChangeHandle = useCallback(
    (value) => () => {
      setState(value)
      onChange && onChange(value)
    },
    []
  )

  return (
    <VStack spacing={2} alignItems="flex-start" w="100%">
      <Text alignSelf="flex-end" textStyle="caption">
        {title}
      </Text>
      {options.map(({ value, label }) => {
        return (
          <HStack spacing={2}>
            <CheckIcon visibility={value === state ? 'visible' : 'hidden'} />
            <Text onClick={onChangeHandle(value)}>{label}</Text>
          </HStack>
        )
      })}
    </VStack>
  )
}

export default MenuGroup