
export const LABORATORY_FIELDS = {
  name: 'name',
  shortName: 'shortTitleRu',
  siteUrl: 'siteUrl',
  telephone: 'telephone',
  countryRu: 'countryRu',
  countryShortRu: 'countryShortRu',
  createdAt: 'createdAt',
  state: 'state',
  inn: 'inn',
  oid: 'oid',
  company: 'company',
  addressLegal: 'addressLegal',
  kpp: 'kpp',
  ogrn: 'ogrn',
  ownership: 'ownership',
  lastPing: 'lastPing',
  appVersion: 'appVersion',
}

export const LABORATORY_FIELDS_TRANSLATION = {
  [LABORATORY_FIELDS.name]: 'laboratory.field.name.label',
  [LABORATORY_FIELDS.shortName]: 'laboratory.field.shortName.label',
  [LABORATORY_FIELDS.telephone]: 'laboratory.field.telephone.label',
  [LABORATORY_FIELDS.siteUrl]: 'laboratory.field.siteUrl.label',
  [LABORATORY_FIELDS.countryRu]: 'country.field.nameRu.label',
  [LABORATORY_FIELDS.countryShortRu]: 'country.field.shortNameRu.label',
  [LABORATORY_FIELDS.createdAt]: 'global.createdDate',
  [LABORATORY_FIELDS.state]: 'laboratory.field.disabled.label',
  [LABORATORY_FIELDS.oid]: 'requisites.oid.label',
  [LABORATORY_FIELDS.inn]: 'requisites.inn.label',
  [LABORATORY_FIELDS.company]: 'requisites.company.label',
  [LABORATORY_FIELDS.ogrn]: 'requisites.ogrn.label',
  [LABORATORY_FIELDS.kpp]: 'requisites.kpp.label',
  [LABORATORY_FIELDS.ownership]: 'requisites.ownership.label',
  [LABORATORY_FIELDS.addressLegal]: 'requisites.addressLegal.label',
  [LABORATORY_FIELDS.lastPing]: 'laboratory.info.lastPing',
  [LABORATORY_FIELDS.appVersion]: 'laboratory.info.appVersion',
}
