import React, { useContext } from 'react';
import { Flex, Box, Checkbox, useColorModeValue } from '@chakra-ui/react';
import { UIInput } from '../deprecated/input';
import { isValidTime, twoDigits } from '../deprecated/utilities/date-formatting';
import { TranslationContext } from '../../translation';

export const WorkScheduleTimeItem = (
{
    label,
    value = [],
    index,
    onChange = null
}) => {
    const { getTranslation } = useContext(TranslationContext);
    const themeBg = useColorModeValue('gray.200', 'gray.700');
    const themeBgHover = useColorModeValue('gray.300', 'gray.600');
    const timeValue = value.length && value[0];
    const fromHours = timeValue ? twoDigits(timeValue.fromHours) : null;
    const fromMinutes = timeValue ? twoDigits(timeValue.fromMinutes) : null;
    const toHours = timeValue ? twoDigits(timeValue.toHours) : null;
    const toMinutes = timeValue ? twoDigits(timeValue.toMinutes) : null;
    const isDayOff = !value.length;
    const isRoundClock =  ((fromHours === '00') && (fromMinutes === '00') &&
        ((toHours === '00') && (toMinutes === '00')));

    const handleInputWorkTime = (e) => {
        const newValue = e.target.value;
        if (newValue === '') {
            onChange && onChange([], index);
            return;
        }
        const [newFrom, newTo] = newValue ? newValue.split(' - ') : ['09:00','18:00'];
        if (isValidTime(newFrom) && isValidTime(newTo)) {

            const [newFromHours, newFromMinutes] = newFrom.split(':');
            const [newToHours, newToMinutes] = newTo.split(':');
            if ((newFromHours === newToHours) && (newFromMinutes === newToMinutes)) {
                if ((newFromHours === '00') && (newFromMinutes === '00') &&
                    ((newFromHours === '00') && (newFromMinutes === '00'))) {
                    onChange && onChange([{
                        fromHours: Number(newFromHours),
                        fromMinutes: Number(newFromMinutes),
                        toHours: Number(newToHours),
                        toMinutes: Number(newToMinutes)
                    }], index);
                } else {
                    onChange && onChange([], index);
                }
            } else {
                onChange && onChange([{
                        fromHours: Number(newFromHours),
                        fromMinutes: Number(newFromMinutes),
                        toHours: Number(newToHours),
                        toMinutes: Number(newToMinutes)
                    }], index);
            }
        } else {
            if (fromHours && fromMinutes && toHours && toMinutes) {
                onChange && onChange([
                    {
                        fromHours: Number(fromHours),
                        fromMinutes: Number(fromMinutes),
                        toHours: Number(toHours),
                        toMinutes: Number(toMinutes)
                    }
                ], index);
            } else {
                onChange && onChange([], index);
            }
        }
    };

    const handleDayOff = (e) => {
        if (e.target.checked) {
            onChange && onChange([], index);
        } else {
            onChange && onChange([
                {
                    fromHours: Number('09'),
                    fromMinutes: Number('00'),
                    toHours: Number('18'),
                    toMinutes: Number('00')
                }
            ], index);
        }
    };

    const handleRoundClock = (e) => {
        if (e.target.checked) {
            onChange && onChange([
                {
                    fromHours: Number('00'),
                    fromMinutes: Number('00'),
                    toHours: Number('00'),
                    toMinutes: Number('00')
                }
            ], index);
        } else {
            onChange && onChange([
                {
                    fromHours: Number('09'),
                    fromMinutes: Number('00'),
                    toHours: Number('18'),
                    toMinutes: Number('00')
                }
            ], index);
        }
    }

    return (
        <Box
            display="grid"
            gridGap="1.25rem"
            role="group"
            gridTemplateColumns="1.875rem 200px 1fr"
            padding="0.375rem 0.625rem"
            alignItems="center"
            transition="0.3s"
            borderRadius={6}
        >
            <Box
                width={30}
                height={30}
                fontSize="0.8rem"
                backgroundColor={themeBg}
                borderRadius="50%"
                transition="0.3s"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _groupHover={{
                    backgroundColor: themeBgHover
                }}
            >
                {label}
            </Box>
            <UIInput
                name="fromInput"
                placeholder="00:00 - 00:00"
                size="sm"
                mask={[/[0-9]/,/[0-9]/,':', /[0-9]/, /[0-9]/,' ', '-', ' ', /[0-9]/,/[0-9]/,':', /[0-9]/, /[0-9]/]}
                value={`${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`}
                onBlur={handleInputWorkTime}
            />

            <Flex
                justify="center"
                justifyContent="flex-start"
                alignItems="center"
                height="100%"
                paddingTop="3px"
                ml={3}
            >
                <Checkbox isChecked={isRoundClock} onChange={handleRoundClock} mr={6}>{getTranslation('workSchedule.item.roundClock')}</Checkbox>
                <Checkbox isChecked={isDayOff} onChange={handleDayOff}>{getTranslation('workSchedule.item.dayOff')}</Checkbox>
            </Flex>
        </Box>
    );
};
