import { api } from 'api'
import { useQuery } from 'components/useQuery'
import { addressToText } from 'components/Geolocation/api/adapters'

const defaultParams = {
  addressdetails: 1,
}

export const useNominationLookup = (initialParams) => {
  const { response, request, ...state } = useQuery(async (params) => {
    return await api.nomination.lookup({ ...defaultParams, ...initialParams, ...params })
  })

  const data = response ? response[0] : undefined

  return {
    ...state,
    response: {
      ...data,
      addressText: data ? addressToText(data.address) : undefined,
    },
    request,
  }
}

