import React, { useContext, useEffect } from 'react'
import { Button, ModalBody, useDisclosure, Box, HStack, useToast } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { FormGrid, FormSection } from 'components/deprecated/layout'
import { ModalForm } from 'components/deprecated/modal'
import * as Yup from 'yup'
import { TranslationContext } from 'translation'
import { CheckboxForm, InputForm } from 'chakra-lib'
import { getSettingsSMTP, testSettingsSMTP, updateSettingsSMTP } from 'store/settings-smtp.reducer'

const formSchema = Yup.object().shape({
  host: Yup.string().required('Required'),
  port: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  from: Yup.string().required('Required'),
})

export const SettingsSMTP = () => {
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const item = useSelector((state) => state.settingsSMTP.item)
  const loading = useSelector((state) => state.settingsSMTP.loading)
  const loadingTest = useSelector((state) => state.settingsSMTP.loadingTest)
  const handleForm = async (values) => {
    try {
      const dataRequest = {
        host: values.host,
        port: values.port,
        useSsl: values.useSsl,
        username: values.username,
        password: values.password,
        from: values.from,
      }
      await dispatch(updateSettingsSMTP(dataRequest))
      toast({
        title: 'Настройки успешно обновлены',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      onClose()
    } catch (err) {
      toast({
        title: 'Произошла ошибка при обновлении',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const handleTest = async (dataRequest) => {
    try {
      const result = await dispatch(testSettingsSMTP(dataRequest))
      const resultTest = result?.action?.payload?.data?.success
      const resultError = result?.action?.payload?.data?.error
      if (resultTest) {
        toast({
          title: 'Соединение установлено',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        return
      }
      toast({
        title: 'Ошибка',
        description: resultError,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: 'Невозможно соединиться',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const handleCloseModal = () => {
    onClose()
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getSettingsSMTP())
  }, [])
  return (
    <>
      <Button onClick={onOpen} width="100%">
        Настройка SMTP
      </Button>
      <ModalForm isOpen={isOpen} onClose={handleCloseModal} heading="Настройка SMTP">
        <Formik
          initialValues={{
            host: item.host,
            port: item.port,
            useSsl: item.useSsl,
            username: item.username,
            password: item.password,
            from: item.from,
          }}
          onSubmit={handleForm}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormSection mb={0}>
                  <FormGrid columns={2}>
                    <Field label="Хост" name="host" component={InputForm} />
                    <Field label="Порт" name="port" component={InputForm} />
                  </FormGrid>
                  <Field name="useSsl" component={CheckboxForm} label="Использовать SSL" />
                  <Field label="От кого" name="from" component={InputForm} />
                  <Field label="Логин" name="username" component={InputForm} />
                  <Field type="password" label="Пароль" name="password" component={InputForm} />
                </FormSection>
                <Box padding="1.5rem 0 0.5rem">
                  <HStack spacing="0.875rem">
                    <Button
                      colorScheme="blue"
                      type="submit"
                      isDisabled={!props.dirty}
                      isLoading={loading}
                    >
                      {getTranslation('global.update')}
                    </Button>
                    <Button
                      colorScheme="gray"
                      isLoading={loadingTest}
                      onClick={() =>
                        handleTest({
                          host: props.values.host,
                          port: props.values.port,
                          useSsl: props.values.useSsl,
                          username: props.values.username,
                          password: props.values.password,
                          from: props.values.from,
                        })
                      }
                    >
                      Тест
                    </Button>
                  </HStack>
                </Box>
              </ModalBody>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  )
}
