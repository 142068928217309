import { api } from 'api'
import { useQuery } from 'components/useQuery'
import toast from 'utils/toast'
import fs from 'file-saver'
import moment from 'moment'

export const useFetchSyncLabData = () => {
  const state = useQuery(
    async (params) => {
      return await api.tests.fetchOfflineSync(params)
    },
    {
      onSuccess: (data) => {
        const blob = new Blob([JSON.stringify(data)], { type: 'text/plain;charset=utf-8' })
        fs.saveAs(blob, `labsync-${moment().format('DDMMYYYY-HHmm')}.json`)
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка загрузки файла',
          description: err.message,
        })
      },
    }
  )

  return state
}
