import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../components/deprecated/utilities/is-empty';
import { Redirect } from 'react-router-dom';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { logoutAuth } from '../store/authentication.reducer';
import { Helmet } from 'react-helmet';
import { TranslationContext } from '../translation';

export const Logout = () => {
    const { getTranslation } = useContext(TranslationContext);
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.authentication.user);
    const isAuthenticated = !isEmpty(userAuth);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(logoutAuth());
    }, [userAuth]);

    return isAuthenticated ? (
        <>
            <Helmet>
                <title>{`COVID`}</title>
            </Helmet>
            <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                padding="60px"
            >
                <Text>
                    {getTranslation('loading')}
                </Text>
                <Spinner color="blue.500" size="md" marginTop="1.25rem" />
            </Box>
        </>
    ) : <Redirect to="/" />
}
