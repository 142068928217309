import React, { useContext, useEffect } from 'react';
import {
  CardContainer, Container,
  ContentContainer,
  ContentContainerColumn, Form, FormGrid, FormHeading, FormSection,
  HeadingContainer
} from '../../../../chakra-lib/layout';
import {
  Alert,
  Heading,
  InputForm,
  InputMaskForm,
  Select,
  SelectForm,
  TextArea,
} from '../../../../chakra-lib';
import { Field, Formik } from 'formik';
import { Button, useToast } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createVaccination,
  getVaccination,
  resetVaccination,
  updateVaccination
} from '../../../../store/vaccination.reducer';
import * as Yup from 'yup';
import { TranslationContext } from '../../../../translation';
import { find, isEmpty } from 'lodash';
import { getDateReadFormat, getDateToSubmit } from '../../../../chakra-lib/utils/dateFormatting';
import { maskBirthday } from '../../../../components/deprecated/input-date/config';
import { GENDER } from '../../../../model/gender';
import { getVaccineList, resetVaccineList } from '../../../../store/vaccine.reducer';
import { ROLES } from '../../../../model/roles';
import { getMedicalFacilityList, resetMedicalFacilityList } from '../../../../store/medicalFacility.reducer';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  birthday: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  vaccineId: Yup.string().required('Required'),
  infectionName: Yup.string().required('Required'),
  createMedicalFacilityId: Yup.string().required('Required'),
});

export const VaccinationUpdate = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const toast = useToast();
  const isNew = !id;
  const item = useSelector(state => state.vaccination.item);
  const loading = useSelector(state => state.vaccination.loading);
  const loadingUpdate = useSelector(state => state.vaccination.loadingUpdate);
  const errorMessage = useSelector(state => state.vaccination.errorMessage);
  const vaccineItems = useSelector(state => state.vaccine.items);
  const vaccineLoading = useSelector(state => state.vaccine.loading);
  const itemsMedicalFacility = useSelector(state => state.medicalFacility.items);
  const loadingMedicalFacility = useSelector(state => state.medicalFacility.loading);
  const userAuth = useSelector(state => state.authentication.user);

  const handleBack = () => {
    history.goBack();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getVaccineList());
    if (userAuth.role === ROLES.Administrator || userAuth.role === ROLES.UserEditor) {
      dispatch(getMedicalFacilityList());
    }
    if (isNew) {
      dispatch(resetVaccination());
    } else {
      dispatch(getVaccination(id));
    }
    return () => {
      dispatch(resetVaccination());
      dispatch(resetVaccineList());
      dispatch(resetMedicalFacilityList());
    };
  }, []);

  const handleForm = async (values, actions) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      vaccineId: values.vaccineId,
      infectionName: values.infectionName,
      uid: values.uid,
      passport: values.passport,
      firstName: values.firstName,
      lastName: values.lastName,
      patronymicName: values.patronymicName,
      birthday: !isEmpty(values.birthday) ? getDateToSubmit(values.birthday) : undefined,
      gender: values.gender,
      invalid: values.invalid,
      countryManufacturer: values.countryManufacturer,
      createMedicalFacilityId: values.createMedicalFacilityId
    };
    if (isNew) {
      await dispatch(createVaccination(dataRequest));
      toast({
        title: 'Готово',
        description: 'Вакцинация успешно создана',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    } else {
      await dispatch(updateVaccination(id, dataRequest));
      toast({
        title: 'Готово',
        description: 'Вакцинация успешно обновлена',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    }
  };

  if (item?.invalid) {
    return (
      <Container>
        Заблокировано
      </Container>
    )
  }

  return (
    <Container>
      <CardContainer indentTop={70} onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание вакцинации' : 'Обновление вакцинации'}
              </Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="1.875rem">
                {errorMessage?.error}
              </Alert>
            )}
            <Formik
              initialValues={{
                lastName: item?.lastName,
                firstName: item?.firstName,
                patronymicName: item?.patronymicName,
                vaccineId: item?.vaccineId,
                infectionName: item?.infectionName ? item?.infectionName : 'Коронавирус COVID-19',
                uid: item?.uid,
                passport: item?.passport,
                birthday: item?.birthday ? getDateReadFormat(item?.birthday) : '',
                gender: item?.gender,
                invalid: item?.invalid,
                countryManufacturer: item?.countryManufacturer,
                createMedicalFacilityId: item?.createMedicalFacilityId ? item?.createMedicalFacilityId : userAuth.medicalFacilityId
              }}
              onSubmit={handleForm}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={loadingUpdate || loading}
                    >
                      {isNew ? getTranslation('test.action.buttonCreate') : getTranslation('test.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <FormGrid columns={2}>
                      <Field
                        autoFocus
                        name="lastName"
                        label="Фамилия"
                        component={InputForm}
                      />
                      <Field
                        name="firstName"
                        label="Имя"
                        component={InputForm}
                      />
                    </FormGrid>
                    <Field
                      name="patronymicName"
                      label="Отчество"
                      component={InputForm}
                    />
                    <FormGrid columns={2}>
                      <Field
                        name="birthday"
                        label="Дата рождения"
                        mask={maskBirthday}
                        placeholder="01.01.1990"
                        component={InputMaskForm}
                      />
                      <Field
                        name="gender"
                        label="Пол"
                        component={SelectForm}
                        placeholder="Выберите пол"
                        isSearchable={false}
                        options={[
                          {value: GENDER.male, label: 'Мужской'},
                          {value: GENDER.female, label: 'Женский'}
                        ]}
                      />
                    </FormGrid>
                  </FormSection>
                  <FormSection>
                    <FormHeading>Документ</FormHeading>
                    <FormGrid columns={2}>
                      <Field
                        name="passport"
                        label="Паспорт"
                        component={InputForm}
                      />
                      <Field
                        name="uid"
                        label="Личный номер\ID гражданина"
                        component={InputForm}
                      />
                    </FormGrid>
                  </FormSection>
                  <FormSection>
                    <FormHeading>Вакцинация</FormHeading>
                    {(userAuth.role === ROLES.Administrator || (userAuth.role === ROLES.UserEditor && isEmpty(userAuth.medicalFacilityId))) && (
                      <Field
                        name="createMedicalFacilityId"
                        label="Медицинское учреждение"
                        component={SelectForm}
                        placeholder="Выберите из списка"
                        isLoading={loadingMedicalFacility}
                        options={
                          !isEmpty(itemsMedicalFacility) && itemsMedicalFacility.map(item => ({
                            value: item.id,
                            label: item.title?.ru
                          }))
                        }
                      />
                    )}
                    <Field name="vaccineId">
                      {({
                          field,
                          form,
                        }) => {
                        const option = !isEmpty(vaccineItems) && vaccineItems.map(item => ({
                          value: item?.id,
                          label: item?.name?.ru
                        }));
                        const handleChange = (e) => {
                          const selectedVaccine = find(vaccineItems, {id: field.value});
                          if (isEmpty(form.values?.countryManufacturer)
                            || form.values?.countryManufacturer === selectedVaccine?.countryManufacturer) {
                            const newVaccine = find(vaccineItems, {id: e.value});
                            if (!isEmpty(newVaccine)) {
                              form.setFieldValue('countryManufacturer', newVaccine?.countryManufacturer)
                            }
                          }
                          option && form.setFieldValue(field.name, e.value);
                        }
                        return (
                          <Select
                            name={field.name}
                            value={field.value}
                            label="Вакцина"
                            placeholder="Выберите вакцину"
                            isLoading={vaccineLoading}
                            options={option || []}
                            isInvalid={form.errors[field.name] && form.touched[field.name]}
                            onChange={handleChange}
                          />
                        )
                      }}
                    </Field>
                    <Field
                      name="infectionName"
                      label="Наименование инфекции"
                      component={InputForm}
                    />
                    <Field name="countryManufacturer">
                      {({
                          field,
                          form,
                          meta,
                        }) => {
                        const handleChange = (e) => {
                          form.setFieldValue(field.name, e.target.value.slice(0, 120))
                        }
                        return (
                          <>
                            <TextArea
                              name={field.name}
                              value={field.value}
                              onChange={handleChange}
                              label="Страна-производитель"
                            />
                          </>
                        )
                      }}
                    </Field>
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
