import React from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'

export const Drawer = ({ title, footer, children, ...props }) => {
  return (
    <ChakraDrawer {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton fontSize="0.8rem" boxSize="2rem"/>
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        <DrawerFooter>{footer}</DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  )
}

