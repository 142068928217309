import React from 'react'
import { Flex } from '@chakra-ui/react'
import { useWindowHeight } from '../../useWindowHeight'
import { isBrowser } from 'react-device-detect'

export const ContainerApp = ({ children }) => {
  const windowHeight = useWindowHeight()

  return (
    <Flex
      flexDirection="column"
      width="100%"
      minWidth={isBrowser ? '1100px' : 'auto'}
      height={`${windowHeight}px`}
    >
      {children}
    </Flex>
  )
}
