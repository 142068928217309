import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FilterFooter, FilterStack } from 'chakra-lib/layout'
import { useSelector } from 'react-redux'
import { getFilterParams } from 'chakra-lib/utils'
import { getURLFromFilterParams } from 'chakra-lib/utils'
import { FormLabel, InputSearch } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { Button, FormControl, Switch, Text, Tooltip } from '@chakra-ui/react'
import { FILTER_LABORATORY_PARAMS } from 'config/laboratory.config'
import { useLaboratorySettings, useLaboratoryStatus } from '../_services'

export const LaboratoryListActions = ({ params, onExportExcel }) => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const { text } = useSelector((state) => state.laboratory.filter)
  const countList = useSelector((state) => state.laboratory.totalItems)
  const [trustedLabsStatus, setTrustedLabsStatus] = useState(false)

  const { request: fetchTrustedLabsStatus } = useLaboratoryStatus({
    onSuccess: setTrustedLabsStatus,
  })

  const { request: sendTrustedLabsStatus } = useLaboratorySettings()

  useEffect(() => {
    fetchTrustedLabsStatus()
  }, [])

  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0]
    const filedValue = param[fieldName]
    const objFilterParams = getFilterParams(FILTER_LABORATORY_PARAMS, params)
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue,
    })
    history.push(`/integration/laboratory${urlFilterParams}`)
  }

  const handleResetFilter = () => {
    history.push(`/integration/laboratory`)
  }

  const onChangeTrustedLabsStatus = useCallback(({ target }) => {
    setTrustedLabsStatus(target.checked)
    sendTrustedLabsStatus(target.checked)
  }, [])

  const label = 'Настройка отображения фильтра "Расширенный поиск" в Мобильном приложении'
  return (
    <>
      <FilterStack>
        <Button onClick={onExportExcel} width="100%">
          Выгрузить в excel
        </Button>
        <InputSearch
          label={getTranslation('global.search')}
          name="text"
          value={text}
          onChange={(e) => handleFilter({ text: e })}
        />
      </FilterStack>
      <FilterFooter>
        <Button size="sm" width="100%" onClick={handleResetFilter}>
          Очистить фильтр
        </Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
      <FilterStack>
        <FormLabel>Опции</FormLabel>
        <FormControl display="flex" alignItems="center">
          <FormLabel>
            <Tooltip label={label}>{getTranslation('settings.trustedLabs')}</Tooltip>
          </FormLabel>
          <Switch isChecked={trustedLabsStatus} onChange={onChangeTrustedLabsStatus} />
        </FormControl>
      </FilterStack>
    </>
  )
}
