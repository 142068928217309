import React from 'react';
import { Alert as ChakraAlert, AlertDescription, AlertIcon, CloseButton } from '@chakra-ui/react';

export const Alert = ({ children, isDisableClose, onCloseClick, ...props }) => {
  return (
    <ChakraAlert status="error" borderRadius={6} {...props}>
      <AlertIcon />
      <AlertDescription
        fontSize="0.88rem"
        lineHeight="1.3"
        mr="1.25rem"
      >
        {children}
      </AlertDescription>
      {!isDisableClose && (
        <CloseButton onClick={onCloseClick} position="absolute" right="0.5rem" top="0.5rem" />
      )}
    </ChakraAlert>
  );
};
