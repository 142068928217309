import React, { useContext } from 'react';
import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';
import { twoDigits } from '../deprecated/utilities/date-formatting';
import { TranslationContext } from '../../translation';

export const WorkScheduleViewItem = ({ label, value = [] }) => {
    const { getTranslation } = useContext(TranslationContext);
    const timeValue = value.length && value[0];
    const fromHours = timeValue ? twoDigits(timeValue.fromHours) : null;
    const fromMinutes = timeValue ? twoDigits(timeValue.fromMinutes) : null;
    const toHours = timeValue ? twoDigits(timeValue.toHours) : null;
    const toMinutes = timeValue ? twoDigits(timeValue.toMinutes) : null;
    const isDayOff = !value.length;
    const themeBg = useColorModeValue('gray.100', 'gray.600');
    const isRoundClock =  ((fromHours === '00') && (fromMinutes === '00') &&
        ((toHours === '00') && (toMinutes === '00')));

    const getValue = () => {
        if (isDayOff) {
            return <Text color="gray.400">{getTranslation('workSchedule.item.dayOff')}</Text>
        } else if (isRoundClock) {
            return <Text>{getTranslation('workSchedule.item.roundClock')}</Text>
        } else return `${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`
    };

    return (
        <Flex
            justifyContent="space-between"
            height="1.25rem"
            alignItems="center"
            backgroundColor={themeBg}
            padding="0.875rem 18px"
            borderRadius={100}
        >
            {label && (
                <Box fontWeight="semibold" fontSize="0.9rem">{label}</Box>
            )}
            <Box>
                {getValue()}
            </Box>
        </Flex>
    );
};
