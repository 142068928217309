import React, { useContext } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { getDateReadFormat, getTimeReadFormat } from '../deprecated/utilities/date-formatting';
import { TranslationContext } from '../../translation';

export const ContentDate = ({loading, item}) => {
  const {getTranslation} = useContext(TranslationContext);
  return (
    !loading && item && (
      <Box padding="0.875rem 0 0" color="gray.400" fontSize="0.7rem" lineHeight="1.6">
        {item.createdAt && (
          <Text>
            {`${getTranslation('global.created')}: ${getDateReadFormat(item.createdAt)}, ${getTimeReadFormat(item.createdAt)}`}
          </Text>
        )}
        {item.updatedAt && (
          <Text>
            {`${getTranslation('global.updated')}: ${getDateReadFormat(item.updatedAt)}, ${getTimeReadFormat(item.updatedAt)}`}
          </Text>
        )}
        {item.synchronizedAt && (
          <Text>
            {`${getTranslation('global.synchronization')}: ${getDateReadFormat(item.synchronizedAt)}, ${getTimeReadFormat(item.synchronizedAt)}`}
          </Text>
        )}
      </Box>
    )
  );
};
