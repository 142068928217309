import { push } from './push'
import { settings } from './settings'
import { formSample } from './formSample'
import { tests } from './tests'
import { testType } from './testType'
import { nomination } from './nomination'

export const api = {
  push,
  settings,
  formSample,
  tests,
  testType,
  nomination,
}
