import React from 'react';
import { Icon } from '@chakra-ui/react';
import { Marker } from 'react-map-gl';
import { IconGeo } from '../icons';

export const MapPins = ({ latitude, longitude, opacity = 1 }) => (
    <Marker
        latitude={latitude}
        longitude={longitude}
        captureDrag={false}
        captureScroll={false}
        captureDoubleClick={false}
        captureClick={false}
        offsetLeft={-26}
        offsetTop={-26}
        className="pointer-events-none"
    >
        <Icon as={IconGeo} boxSize="1.75rem" color="blue.500" opacity={opacity} />
    </Marker>
);
