import React from 'react';
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

export const ModalForm = ({ heading, isOpen, onClose, size = '500px', children, ...props }) => {
    return (
        <Modal
            blockScrollOnMount={false}
            isOpen={isOpen}
            onClose={onClose}
            {...props}
        >
            <ModalOverlay />
            <ModalContent borderRadius={6} paddingBottom="0.625rem">
                <ModalHeader>{heading}</ModalHeader>
                <ModalCloseButton top="0.75rem"/>
                {children}
            </ModalContent>
        </Modal>
    );
};
