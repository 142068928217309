import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Image } from '@chakra-ui/react';
import { Spinner } from '../../chakra-lib';
import empty from '../../images/empty-image.svg';

export const DropZoneImage = ({ uploadedImage, onUpload, onError, maxFiles, multiple  })  => {
  const [fullSize, setFullSize] = useState();
  const loadingUploadImage = false;
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 1048576,
    maxFiles: maxFiles,
    multiple: multiple,
    onDropAccepted: (e) => {
      setFullSize(false);
      onUpload(e);
    },
    onDropRejected: () => {
      setFullSize(false);
      onError()
    },
  });
  return (
    <section className="dropzone-input">
      <Box
        mt="0.25rem"
        mb="0.25rem"
        width="100%"
        height="160px"
        borderWidth={1}
        borderRadius={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="0.2s"
        outline="none"
        cursor="pointer"
        borderColor={fullSize ? 'blue.300' : 'gray.200'}
        backgroundImage={`url(${empty})`}
        overflow="hidden"
        {...getRootProps({className: 'dropzone'})}
        _hover={{
          borderColor: 'blue.300'
        }}
      >
        <input {...getInputProps()} />
        <Image src={uploadedImage}/>
        {loadingUploadImage && (
          <Spinner
            color="blue.500"
            size="sm"
            marginRight="0.625rem"
          />
        )}
      </Box>
    </section>
  );
};
