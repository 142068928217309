import { api } from 'api'
import { useQuery } from 'components/useQuery'
import toast from 'utils/toast'

export const useUploadSyncLabData = () => {
  const state = useQuery(
    async (values) => {
      return await api.tests.sendOfflineSync(values)
    },
    {
      onSuccess: () => {
        toast.showError({
          title: 'Готово',
          description: 'Данные успешно загружены',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка загрузки файла',
          description: err.message,
        })
      },
    }
  )

  return state
}
