import React, { useContext, useEffect } from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
  HeadingContainer,
  RightAside,
} from 'chakra-lib/layout'
import { Heading } from 'chakra-lib'
import { isEmpty } from 'lodash'
import { ContentDate } from 'components/content-date'
import { TranslationContext } from 'translation'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLaboratory, resetLaboratory } from 'store/laboratory.reducer'
import { RequisitesShow } from 'components/requisites'
import { MapPosition } from 'components/map/MapPosition'
import { WorkScheduleView } from 'components/work-schedule-view'

const OfficeView = ({ data, loading, isReadyItem }) => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const loadingLaboratory = useSelector((state) => state.laboratory.loading)
  const itemLaboratory = useSelector((state) => state.laboratory.item)
  const isReadyItemLaboratory = !isEmpty(itemLaboratory)

  useEffect(() => {
    if (!isEmpty(data) && data.laboratoryId) {
      dispatch(getLaboratory(data.laboratoryId))
    }
    return () => {
      dispatch(resetLaboratory())
    }
  }, [data])

  const handleBack = () => {
    history.goBack()
  }

  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">{getTranslation('office.heading.show')}</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.data')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('office.field.titleRu.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {data.title?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.titleEn.placeholder')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {data.title?.en}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.shortTitleRu.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {data.shortTitle?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.shortTitleEn.placeholder')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {data.shortTitle?.en}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.telephone.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {data.telephone && data.telephone}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.siteUrl.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {data.siteUrl && data.siteUrl}
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>
                  {getTranslation('office.section.heading.location')}
                </GridFieldHeading>
                <GridFieldItem isLoading={loading} isReady={isReadyItem}>
                  {data.address && data.address.ru}
                </GridFieldItem>
                <GridFieldItem isLoading={loading} isReady={isReadyItem}>
                  {data.address && data.address.en}
                </GridFieldItem>
                <MapPosition
                  latitude={
                    data.coordinates && data.coordinates.lat ? data.coordinates.lat : undefined
                  }
                  longitude={
                    data.coordinates && data.coordinates.lng ? data.coordinates.lng : undefined
                  }
                />
              </GridField>
              <GridField>
                <GridFieldHeading>
                  {getTranslation('office.section.heading.requisites')}
                </GridFieldHeading>
                <RequisitesShow item={data} loading={loading} isReadyItem={isReadyItem} />
              </GridField>
              {(data.details?.comment?.ru || data.details?.comment?.ru) && (
                <GridField>
                  <GridFieldHeading>
                    {getTranslation('office.section.heading.comment')}
                  </GridFieldHeading>
                  {data.details?.comment?.ru && (
                    <GridFieldItem isLoading={loading} isReady={isReadyItem}>
                      {data.details.comment.ru}
                    </GridFieldItem>
                  )}
                  {data.details?.comment?.en && (
                    <GridFieldItem isLoading={loading} isReady={isReadyItem}>
                      {data.details?.comment?.en}
                    </GridFieldItem>
                  )}
                </GridField>
              )}
              <GridField>
                <GridFieldHeading>
                  {getTranslation('office.section.heading.laboratory')}
                </GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('laboratory.field.name.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.name && itemLaboratory.name}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.shortName.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.shortName && itemLaboratory.shortName}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.telephone.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.telephone && itemLaboratory.telephone}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.siteUrl.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.siteUrl && itemLaboratory.siteUrl}
                </GridFieldItem>
              </GridField>
            </ContentContainerColumn>
            <ContentContainerColumn>
              <RightAside>
                <WorkScheduleView isLoading={loading} value={!isEmpty(data) && data.workingHours} />
                <ContentDate item={data} loading={loading} />
              </RightAside>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
    </>
  )
}

export default OfficeView