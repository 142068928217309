import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Image, Box, Text, Flex, HStack, VStack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Logo from 'images/logo512.png'
import { ContainerApp, Header, NavigationMenuItem } from 'chakra-lib/layout'
import { ProfileWidget, ProfileWidgetMobile } from 'components/ProfileWidget'
import { isEmpty } from 'lodash'
import {
  getAutoNumberingEnabled,
  getAutoNumberingPrefix,
  getCountry,
  getSettingsLaboratory,
} from 'store/settings.reducer'
import { Private, PrivateRoute } from 'components/Private'
import { ROLES } from 'model/roles'
import { LoadingSettings } from 'components/deprecated/layout'
import { Login } from 'login'
import { TranslationContext } from 'translation'
import Test from './Test'
import Office from './Office'
import User from './User'
import { BrowserView, MobileView } from 'react-device-detect'

export const Laboratory = () => {
  const { getTranslation } = useContext(TranslationContext)

  const dispatch = useDispatch()
  const userAuth = useSelector((state) => state.authentication.user)
  const settingsLaboratory = useSelector((state) => state.settings.settingsLaboratory)
  const testValidHours = useSelector((state) => state.settings.testValidHours)
  const country = useSelector((state) => state.settings.country)
  const version = useSelector((state) => state.settings.version)
  const versionBack = useSelector((state) => state.settings.versionBack)
  const doneLoading = !isEmpty(settingsLaboratory) && testValidHours && country
  const { url } = useRouteMatch()
  const panelName = 'Laboratory'
  const isAuthenticated = !isEmpty(userAuth)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSettingsLaboratory())
      dispatch(getCountry())
      dispatch(getAutoNumberingEnabled())
      dispatch(getAutoNumberingPrefix())
    }
  }, [isAuthenticated])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.querySelector('html').style.overflow = 'hidden'
  }, [])

  const renderLogo = (
    <Flex alignItems="center">
      <Image src={Logo} boxSize="2.75rem" alt="logo" />
      <Box ml={3}>
        <Text textStyle="h4">{panelName}</Text>
        <Text textStyle="version">{`front: ${version} back: ${versionBack}`}</Text>
      </Box>
    </Flex>
  )

  const navigationConfig = [
    {
      hasAnyRole: [
        ROLES.Administrator,
        ROLES.UserPrivileged,
        ROLES.User,
        ROLES.UserCreator,
        ROLES.UserLab,
        ROLES.UserResultSetter,
      ],
      id: 'test',
      path: '/laboratory/test',
      title: getTranslation('menu.test'),
    },
    {
      id: 'office',
      hasAnyRole: [ROLES.Administrator],
      path: '/laboratory/office',
      title: getTranslation('menu.office'),
    },
    {
      id: 'user',
      hasAnyRole: [ROLES.Administrator],
      path: '/laboratory/user',
      title: getTranslation('menu.user'),
    },
  ]

  return (
    <>
      {isAuthenticated ? (
        doneLoading ? (
          <>
            <Helmet>
              <title>{`COVID ${panelName}`}</title>
            </Helmet>
            <ContainerApp>
              <BrowserView>
                <Header
                  subTitle={settingsLaboratory.name}
                  renderLogo={renderLogo}
                  renderNavigationItem={
                    <HStack spacing={2}>
                      {navigationConfig.map(({ id, hasAnyRole, path, title }) => (
                        <Private key={id} role={userAuth.role} hasAnyRole={hasAnyRole}>
                          <NavigationMenuItem to={path}>{title}</NavigationMenuItem>
                        </Private>
                      ))}
                    </HStack>
                  }
                  renderProfileWidget={<ProfileWidget downloadSyncData />}
                />
              </BrowserView>
              <MobileView>
                <ProfileWidgetMobile
                  renderLogo={renderLogo}
                  renderNavigation={
                    <VStack spacing={2} alignItems="flex-start" w="100%">
                      <Text alignSelf="flex-end" textStyle="caption">
                        {getTranslation('userPanel.navigation')}
                      </Text>
                      {navigationConfig.map(({ id, hasAnyRole, path, title }) => (
                        <Private key={id} role={userAuth.role} hasAnyRole={hasAnyRole}>
                          <NavigationMenuItem to={path}>{title}</NavigationMenuItem>
                        </Private>
                      ))}
                    </VStack>
                  }
                />
              </MobileView>
              <Box overflow="hidden" flexGrow={1}>
                <Switch>
                  <PrivateRoute
                    path={`${url}/test`}
                    role={userAuth.role}
                    hasAnyRole={[
                      ROLES.User,
                      ROLES.UserPrivileged,
                      ROLES.UserCreator,
                      ROLES.UserLab,
                      ROLES.Administrator,
                      ROLES.UserResultSetter,
                    ]}
                  >
                    <Test />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/office`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Office />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/user`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <User />
                  </PrivateRoute>
                  <Route path={`/`}>
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[
                        ROLES.Administrator,
                        ROLES.UserPrivileged,
                        ROLES.User,
                        ROLES.UserLab,
                        ROLES.UserCreator,
                        ROLES.UserResultSetter,
                      ]}
                    >
                      <Redirect to="/laboratory/test" />
                    </Private>
                  </Route>
                </Switch>
              </Box>
            </ContainerApp>
          </>
        ) : (
          <LoadingSettings />
        )
      ) : (
        <Login />
      )}
    </>
  )
}
