import React from 'react'

import { Text, Link } from 'chakra-lib'
import { ItemBadge, ItemIcon } from 'chakra-lib/Snippets'
import { IconGeo } from 'components/icons'
import { OFFICE_FIELDS, OFFICE_FIELDS_TRANSLATION } from '../Office.constants'
import { Grid } from '@chakra-ui/react'
import moment from 'moment'
import { DATE_FORMAT } from 'components/deprecated/utilities/date-formatting'

const TYPE_COLOR_RESULT = {
  true: 'red',
  false: 'green',
}

export const makeColumns = ({ getTranslation }) => {
  const TYPE_DISABLED = {
    true: getTranslation('disabled.true'),
    false: getTranslation('disabled.false'),
  }

  return [
    {
      id: 'icon',
      accessor: 'id',
      gridWidth: '3.125rem',
      Cell: () => <ItemIcon icon={IconGeo} />,
    },
    {
      id: OFFICE_FIELDS.title,
      accessor: 'title',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => {
        return (
          <Grid gap={1}>
            <Text isTruncated>{value?.ru}</Text>
            <Text isTruncated fontSize="0.76rem" color="gray.400">
              {value?.en}
            </Text>
          </Grid>
        )
      },
    },
    {
      id: OFFICE_FIELDS.shortTitleRu,
      accessor: 'shortTitle',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => {
        return (
          <Grid gap={1}>
            <Text isTruncated>{value?.ru}</Text>
            <Text isTruncated fontSize="0.76rem" color="gray.400">
              {value?.en}
            </Text>
          </Grid>
        )
      },
    },
    {
      id: OFFICE_FIELDS.siteUrl,
      accessor: 'siteUrl',
      gridWidth: 200,
      Cell: ({ value }) => <Link isExternal>{value}</Link>,
      preventRowClick: true,
    },
    {
      id: OFFICE_FIELDS.telephone,
      accessor: 'telephone',
      gridWidth: 180,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: OFFICE_FIELDS.oid,
      accessor: ({ details }) => details?.oid,
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.addressRu,
      accessor: ({ address }) => address?.ru,
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.inn,
      accessor: ({ details }) => details?.inn,
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.company,
      accessor: ({ details }) => details?.company,
      gridWidth: 220,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: OFFICE_FIELDS.ogrn,
      accessor: ({ details }) => details?.ogrn,
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.ownership,
      accessor: ({ details }) => details?.ownership,
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.addressLegal,
      accessor: ({ details }) => details?.addressLegal,
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.laboratoryName,
      accessor: 'laboratoryName',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: OFFICE_FIELDS.laboratoryShortName,
      accessor: 'laboratoryShortName',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: OFFICE_FIELDS.laboratorySiteUrl,
      accessor: 'laboratorySiteUrl',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: OFFICE_FIELDS.laboratoryTelephone,
      accessor: 'laboratoryTelephone',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: OFFICE_FIELDS.createdAt,
      accessor: ({ createdAt }) => moment(createdAt).format(DATE_FORMAT),
      gridWidth: 180,
    },
    {
      id: OFFICE_FIELDS.state,
      accessor: 'disabled',
      gridWidth: 180,
      Cell: ({ value }) => (
        <ItemBadge colorScheme={TYPE_COLOR_RESULT[value]}>{TYPE_DISABLED[value]}</ItemBadge>
      ),
    },
  ].map(mapItemHeader({ getTranslation }))
}

export const mapItemHeader =
  ({ getTranslation }) =>
  (item) => {
    const translateId = OFFICE_FIELDS_TRANSLATION[item.id]

    if (translateId) {
      let translate = getTranslation(translateId)
      const isOfficeField = [
        OFFICE_FIELDS.laboratoryName,
        OFFICE_FIELDS.laboratoryShortName,
        OFFICE_FIELDS.laboratoryTelephone,
        OFFICE_FIELDS.laboratorySiteUrl,
      ].includes(item.id)

      if (isOfficeField) {
        translate = 'Лаборатория. ' + translate
      }

      return { ...item, Header: translate }
    }

    return item
  }