import React, { useContext, useEffect } from 'react'
import {
  Button,
  Heading,
  Text,
  Box,
  Alert,
  Image,
  useColorModeValue,
  Center,
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { UIInputForm } from 'components/deprecated/input'
import { loginAuth } from 'store/authentication.reducer'
import { useDispatch, useSelector } from 'react-redux'
import Logo from './logo192.png'
import { TranslationContext } from 'translation'
import { useHistory } from 'react-router-dom'
import { useWindowHeight } from 'chakra-lib'
import { isMobile } from 'react-device-detect'

const validateInput = (value) => {
  let error
  if (!value) {
    error = 'Поле не может быть пустым'
  }
  return error
}

export const Login = () => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const windowHeight = useWindowHeight()
  const themeBg = useColorModeValue('gray.50', 'gray.800')
  const themeBgContainer = useColorModeValue('white', 'gray.700')
  const themeBorderWidth = useColorModeValue('1px', 'none')
  const loading = useSelector((state) => state.authentication.loading)
  const errorMessage = useSelector((state) => state.authentication.errorMessage)
  const error = useSelector((state) => state.authentication.error)
  const version = useSelector((state) => state.settings.version)
  const update = useSelector((state) => state.settings.update)
  const adminPanelName = useSelector((state) => state.settings.adminPanelName)
  const versionBack = useSelector((state) => state.settings.versionBack)

  const handleForm = async (values) => {
    const dataRequest = {
      username: values.username,
      password: values.password,
    }
    await dispatch(loginAuth(dataRequest))
  }

  const TYPE_HEADING_ADMIN_PANEL = {
    laboratory: getTranslation('headingAdminPanel.laboratory'),
    national: getTranslation('headingAdminPanel.national'),
    integration: getTranslation('headingAdminPanel.integration'),
  }

  useEffect(() => {
    if (error && history.location.pathname !== '/') {
      history.push('/')
    }
  })

  return (
    <Box
      width="100%"
      height={`${windowHeight}px`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={themeBg}
    >
      <Center
        w={isMobile ? '100%' : '370px'}
        h={isMobile ? '100%' : 'auto'}
        backgroundColor={themeBgContainer}
        p="2.5rem"
        borderRadius={8}
        borderWidth={themeBorderWidth}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          marginBottom="1.875rem"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Image src={Logo} boxSize={isMobile ? '300px' : '96px'} alt="" marginBottom="1.25rem" />
          <Heading as="h1" size="md" mb={2}>
            {adminPanelName !== '' ? adminPanelName : TYPE_HEADING_ADMIN_PANEL[adminPanelName]}
          </Heading>
          <Text mb={3}>
            {getTranslation('login.description')}
          </Text>
        </Box>
        {errorMessage && (
          <Alert mb={4} status="error" borderRadius={6} justifyContent="center">
            {getTranslation('login.error')}
          </Alert>
        )}
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={handleForm}
        >
          {(props) => (
            <Box as="form" onSubmit={props.handleSubmit} w="100%">
              <Box mb={4}>
                <Field
                  name="username"
                  autoFocus
                  component={UIInputForm}
                  validate={validateInput}
                  placeholder={getTranslation('login.field.username')}
                />
              </Box>
              <Box mb={8}>
                <Field
                  name="password"
                  component={UIInputForm}
                  validate={validateInput}
                  placeholder={getTranslation('login.field.password')}
                  type="password"
                />
              </Box>
              <Box>
                <Button type="submit" colorScheme="blue" width="100%" isLoading={loading}>
                  {getTranslation('login.action.buttonSubmit')}
                </Button>
              </Box>
              <Box mt={8} color="gray.300" fontSize="xs" style={{ textAlign: 'center' }}>
                {`Frontend: ${version} (${update})`} <br />
                {`Backend: ${versionBack}`}
              </Box>
            </Box>
          )}
        </Formik>
      </Center>
    </Box>
  )
}
