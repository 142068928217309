import axios from 'axios'
import { API_SERVER_URL } from 'store/config'
import { getXPaginationInfo } from 'api/utils'

const ENTITY = 'test'

export const tests = Object.freeze({
  async fetchList(params) {
    const response = await axios.request({
      url: `${API_SERVER_URL}/${ENTITY}`,
      method: 'GET',
      params,
    })

    return {
      list: response.data,
      ...getXPaginationInfo(response),
    }
  },
  async findList({ data, params }) {
    const response = await axios.request({
      url: `${API_SERVER_URL}/${ENTITY}/findtext`,
      method: 'POST',
      params,
      data,
    })

    return {
      list: response.data,
      ...getXPaginationInfo(response),
    }
  },
  async fetchItem(id, params) {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/${id}`, { params })
    return response.data
  },
  async createItem(values) {
    const response = await axios.post(`${API_SERVER_URL}/${ENTITY}`, values)
    return response.data
  },
  async updateItem(values) {
    const response = await axios.put(`${API_SERVER_URL}/${ENTITY}`, values)
    return response.data
  },
  async eraseItem(id, values) {
    const response = await axios.put(`${API_SERVER_URL}/${ENTITY}/${id}/erase`, values)
    return response.data
  },
  async setInvalidItem(id, params) {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/${id}/setinvalid`, { params })
    return response.data
  },
  async sendEmail(id, params) {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/${id}/sendmail`, { params })
    return response.data
  },
  async sendConsentEmail(id, params) {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/${id}/send_consent_form`, {
      params,
    })
    return response.data
  },
  async fetchOfflineSync(params) {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/offlinesynchronization`, {
      params,
    })
    return response.data
  },
  async sendOfflineSync(values) {
    const response = await axios.post(`${API_SERVER_URL}/${ENTITY}/offlinesynchronisation`, values)
    return response.data
  },
})
