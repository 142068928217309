import React from 'react'

import { Box, VStack, Progress } from '@chakra-ui/react'
import {
  getDateReadFormat,
  getTimeReadFormat,
} from 'components/deprecated/utilities/date-formatting'
import { useTestResult } from 'components/test-result'

const TestingDate = ({ testingDate }) => {
  const { getDiffDate, getRemainingTime, getRemainingPercent } = useTestResult()

  return (
    <VStack>
      <Box fontSize="0.87rem" position="relative" role="group" w="100%">
        <Box
          position="relative"
          transition="0.1s"
          w="100%"
          _groupHover={{
            opacity: 0,
            transform: 'translate3d(0, 0.625rem, 0)',
          }}
        >
          {testingDate
            ? `${getDateReadFormat(testingDate)}, ${getTimeReadFormat(testingDate)}`
            : null}
        </Box>
        <Box
          position="absolute"
          top={0}
          opacity={0}
          transition="0.1s"
          transform="translate3d(0, -0.625rem, 0)"
          _groupHover={{
            opacity: 1,
            transform: 'translate3d(0, 0, 0)',
          }}
        >
          {getRemainingTime(getDiffDate(testingDate))}
        </Box>
      </Box>
      <Progress
        hasStripe
        isAnimated
        height="0.25rem"
        mt={2}
        borderRadius={8}
        width="130px"
        value={getRemainingPercent(getDiffDate(testingDate))}
      />
    </VStack>
  )
}

export default TestingDate