import React, { useCallback, useRef } from 'react'
import { Flex, Switch, Text } from '@chakra-ui/react'

const OptionsForm = ({ options, onChange, wrapperProps }) => {
  const stateRef = useRef({})

  const onChangeHandle = useCallback((event) => {
    const { id, checked } = event.target
    stateRef.current[id] = checked
    onChange && onChange(stateRef.current)
  }, [])

  return options.map(({ id, label, ...props }) => (
    <Flex key={id} alignItems="center" {...wrapperProps}>
      <Text>{label}</Text>
      <Switch id={id} onChange={onChangeHandle} {...props} />
    </Flex>
  ))
}

export default OptionsForm